import { Grid2 as Grid, Button, Box, useTheme } from "@mui/material";
import grey from "@mui/material/colors/grey";
import red from "@mui/material/colors/red";

interface RecapPageItemType {
  item: any;
  value?: any;
  action?: any;
  filesStage?: any;
  filesUploaded?: any;
  isError: boolean;
}

const RecapPageItem = ({
  item,
  value,
  action,
  filesStage,
  filesUploaded,
  isError,
}: RecapPageItemType) => {
  const theme = useTheme();

  const getFilesStage = () => {
    let items: any = [];

    if ("create" in filesStage) {
      filesStage.create.forEach((item: any, index: number) => {
        items.push(<Grid key={index}>{item.name}</Grid>);
      });
    } else {
      return "";
    }

    return items;
  };

  const getFilesUploaded = () => {
    let items: any = [];

    filesUploaded.forEach((item: any, index: number) => {
      items.push(<Grid key={index}>{item.filename}</Grid>);
    });
    return items;
  };

  return (
    <>
      <Box
        sx={{
          borderRadius: theme.spacing(1),
          border: "solid 1px #c1c1c1",
          backgroundColor: isError ? red[50] : grey[50],
        }}
        mb={1.5}
        p={1}
      >
        <Grid container>
          <Grid sx={{ marginTop: "auto", marginBottom: "auto" }} flex={1}>
            {item.label}:
          </Grid>

          {filesStage && getFilesStage()}

          {filesUploaded && getFilesUploaded()}

          {value && <Grid>{value}</Grid>}

          {action && (
            <Grid>
              <Button size="small" variant="outlined" onClick={() => action()}>
                Vyplnit
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default RecapPageItem;
