import { useState, useEffect } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import {
  Button,
  Typography,
  Box,
  Container,
  Card,
  CardContent,
  CardMedia,
  Alert,
  useTheme,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import { TextField } from "formik-material-ui";
import { Formik, Field, Form } from "formik";
import loginHero from "../../image/login-hero.jpg";

import { LOGIN_EXTERNAL_USER } from "./gql";
import { LoginFormValuesType } from "../../utils/Types/LoginFormValuesType";
import { LoginFormErrorsType } from "../../utils/Types/LoginFormErrorsType";
import CopyrightFooter from "../../components/CopyrightFooter";

const ExternalLogin = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('redirectTo');

  const [submit, { data }] = useMutation(LOGIN_EXTERNAL_USER);

  const [isShowError, setShowError] = useState(false);

  useEffect(() => {
    if (data) {
      if (data.logInExternalUser.user) {
        navigate(redirectTo || "/");
        window.location.reload();
      }
    }
  }, [data, location.pathname, navigate, redirectTo]);

  const handleFormSubmit = (
    vals: LoginFormValuesType,
    { setSubmitting }: any
  ) => {
    submit({
      variables: {
        input: {
          email: vals.email,
          password: vals.password,
        },
      },
    })
      .catch((err) => {
        console.log(err);
        setShowError(true);
        setSubmitting(false);
      })
      .then((succ) => {
        setSubmitting(false);
      });
  };

  const validation = (values: LoginFormValuesType) => {
    const errors: LoginFormErrorsType = {};
    const requiredMsg = "Pole musí být vyplněno";

    if (!values.password) {
      errors.password = requiredMsg;
    }
    if (!values.email) {
      errors.email = requiredMsg;
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Neplatný email";
    }

    return errors;
  };

  const initialFormValues: LoginFormValuesType = {
    email: "",
    password: "",
  };

  return (
    <>
      <Container maxWidth="md">
        <Card
          sx={{
            marginTop: theme.spacing(3),
            [theme.breakpoints.up("sm")]: {
              display: "flex",
            },
          }}
        >
          <CardMedia
            sx={{
              minHeight: 105,
              minWidth: 105,

              [theme.breakpoints.up("sm")]: {
                width: 450,
                minHeight: 450,
              },
            }}
            component="img"
            image={loginHero}
          />
          <CardContent
            sx={{
              padding: theme.spacing(3),

              [theme.breakpoints.up("sm")]: {
                padding: theme.spacing(6),
              },
            }}
          >
            <Box mb={1}>
              <Typography variant="h4">Přihlášení do aplikace Metro</Typography>
            </Box>
            {isShowError && (
              <Box mb={2}>
                <Alert severity="error">
                  Zadaná kombinace emailu a hesla je nesprávná
                </Alert>
              </Box>
            )}

            <Formik
              initialValues={initialFormValues}
              validate={validation}
              onSubmit={handleFormSubmit}
            >
              <Form>
                <label htmlFor="email">Login:</label>
                <Field
                  component={TextField}
                  helperText=" "
                  variant="outlined"
                  size="small"
                  margin="dense"
                  fullWidth
                  name="email"
                  autoComplete="email"
                />

                <label htmlFor="password">Heslo:</label>
                <Field
                  component={TextField}
                  helperText=" "
                  type="password"
                  variant="outlined"
                  size="small"
                  margin="dense"
                  fullWidth
                  name="password"
                  autoComplete="current-password"
                />

                <Box mt={1}>
                  <Button
                    disableElevation
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                  >
                    Přihlásit se
                  </Button>
                </Box>
              </Form>
            </Formik>
            <Box mt={2}>
              <Link to="/password/recovery">Zapomněli jste heslo?</Link>
            </Box>
          </CardContent>
        </Card>

        <CopyrightFooter />
      </Container>
    </>
  );
};

export default ExternalLogin;
