import { useTheme } from "@mui/material";
import TabPage from "./TabPage";
import SwipeableViews from "react-swipeable-views";

const TabControl = (props) => {
  const theme = useTheme();

  const { value, setValue, pages, autoUpdate } = props;

  const handleChangeIndex = (index) => {
    setValue(index);
    autoUpdate();
  };

  return (
    <div>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {pages.map((item, index) => {
          return (
            <TabPage
              key={index}
              value={value}
              index={index}
              dir={theme.direction}
            >
              {item.page}
            </TabPage>
          );
        })}
      </SwipeableViews>
    </div>
  );
};

export default TabControl;
