import React from "react";
import {Field} from "formik";
import {useFormikContext} from 'formik';
import {CheckboxWithLabel} from 'formik-material-ui';

const CheckboxShowmore = (props) =>{

    const {isDisabled, name, label, componentToShow} = props;

    const { values } = useFormikContext();

    return (
        <>
        <Field
            color="primary"
            disabled={isDisabled}
            component={CheckboxWithLabel}
            name={name}
            checked={values[name]}
            Label={{ label: label, labelPlacement: "end" }}
        />

        {values[name] && 
            componentToShow
        }
        </>
    )
}

export default CheckboxShowmore;