import { Typography, Grid2 as Grid, IconButton, useTheme } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import DeleteIcon from "@mui/icons-material/Delete";
import { lightGreen } from "@mui/material/colors";

interface UploadDisplayType {
  files: any;
  handleDelete: any;
  filesName: any;
  isDisabled: boolean;
}

const UploadDisplay = ({
  files,
  handleDelete,
  filesName,
  isDisabled,
}: UploadDisplayType) => {
  const theme = useTheme();

  if (!files) return <Typography>Nejsou nahrané žádné soubory</Typography>;

  return (
    <>
      <Typography>Nahrané soubory</Typography>
      <Grid>
        {files.map((item: any, index: number) => {
          return (
            <Grid
              container
              sx={{
                backgroundColor: lightGreen["A100"],
                padding: theme.spacing(0.5),
                paddingLeft: theme.spacing(2),
                borderRadius: theme.shape.borderRadius,
                alignItems: "center",
                marginBottom: theme.spacing(1),
                border: "solid 1px #c1c1c1",
              }}
              key={index}
            >
              <Grid sx={{ xs: 8 }} flex={1}>
                <Typography display="inline">{item.filename}</Typography>
              </Grid>
              <Grid>
                <IconButton
                  href={`${process.env.REACT_APP_SERVER_URL}/api/file/${item.id}`}
                >
                  <GetAppIcon fontSize="small" />
                </IconButton>
                {handleDelete && (
                  <IconButton
                    disabled={isDisabled}
                    onClick={() => handleDelete(item.id, item.filename)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default UploadDisplay;
