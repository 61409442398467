import { getDictionary } from "../../../utils/QuestionnaireUtils";

const isFieldEmpty = (item) => {
  if (item === null || item === "") {
    return true;
  } else if (Array.isArray(item)) {
    if (item.length === 0) return true;
  } else if (typeof item === "object" && item !== null) {
    if (item.create) {
      if (item.create.length === 0) return true;
    }
  }

  return false;
};

//calculates number of fields to be filled and already filled fields
const calculateProgress = (qst) => {
  let baseCount = 0;
  let countEmpty = 0;

  const pages = getDictionary(qst.isForeigner, qst.typeOfCandidate);

  Object.keys(pages).forEach((pageNumber, index) => {
    let itemsOnPage = pages[pageNumber];

    itemsOnPage.forEach((item, index2) => {
      if (item.isRequired) {
        baseCount++;
        // console.log(item.name)
        //check empty non filled fields
        if (isFieldEmpty(qst[item.name])) {
          // console.log("is Empty")
          countEmpty++;
        }
      }

      if (item.componentToShow) {
        item.componentToShow.forEach((itemTmp) => {
          if (itemTmp.isRequired) {
            // console.log(itemTmp.name)
            baseCount++;
            if (isFieldEmpty(qst[itemTmp.name])) {
              countEmpty++;
              // console.log("sub is Empty")
            }
          }
        });
      }
    });
  });

  if (!qst.isBankAccountShow) {
    baseCount += -1;
    //fixing edge case when bank account is not required but the field was already filled
    if (isFieldEmpty(qst.bankAccountNumber)) {
      countEmpty += -1;
    }
  }

  if (!qst.isContactAddressDifferent) {
    //3 inputs of contact address
    baseCount += -3;
    if (isFieldEmpty(qst.contactAddressCity)) countEmpty += -1;
    if (isFieldEmpty(qst.contactAddressPostnumber)) countEmpty += -1;
    if (isFieldEmpty(qst.contactAddressStreet)) countEmpty += -1;
  }

  if (!qst.isSeizure) {
    baseCount += -2;
    if (isFieldEmpty(qst.seizureNumber)) countEmpty += -1;
    if (isFieldEmpty(qst.children)) countEmpty += -1;
  }

  // if(qst.typeOfCandidate == 1){

  // }

  if (!qst.isTradeLicense) {
    baseCount += -1;
    if (isFieldEmpty(qst.tradingLicenseNumber)) countEmpty += -1;
  }

  if (qst.typeOfCandidate != 3) {
    //no need to upload employeeFile
    if (qst.employmentOrigin == "846" || qst.employmentOrigin === null) {
      baseCount += -1;
      countEmpty += -1;

      if (!isFieldEmpty(qst.employeeFile)) {
        countEmpty += 1;
      }
    }

    if (qst.isDeliverFileLater) {
      baseCount += -1;
      countEmpty += -1;

      //TODO: this is temporary solution so the current and max values dont get wrong,
      //Should be fixed by disabling either the deliverLater checkBox or upload file
      if (!isFieldEmpty(qst.employeeFile)) {
        countEmpty += 1;
      }
    }

    if (qst.isEducationDeliverFileLater) {
      baseCount += -1;
      countEmpty += -1;

      //TODO: this is temporary solution so the current and max values dont get wrong,
      //Should be fixed by disabling either the deliverLater checkBox or upload file
      if (!isFieldEmpty(qst.educationDocuments)) {
        countEmpty += 1;
      }
    }

    if (
      qst.educationLevel === "9" ||
      qst.educationLevel === "8" ||
      qst.educationLevel === "7"
    ) {
      baseCount += -1;
      countEmpty += -1;
    }
  }

  //ANNOTATOR and PARTTIMER
  if (qst.typeOfCandidate == 2 || qst.typeOfCandidate == 3) {
    //no need to upload  healthInsurancePayerDocuments
    if (
      qst.otherHealthInsurancePayer == 1 ||
      qst.otherHealthInsurancePayer === null
    ) {
      baseCount += -1;
      countEmpty += -1;

      if (!isFieldEmpty(qst.healthInsurancePayerDocuments)) {
        countEmpty += 1;
      }
    }

    if (qst.otherHealthInsurancePayer != 1) {
      if (isFieldEmpty(qst.companyIdentificationNumber)) {
        countEmpty += -1;
      }
    }
  }

  // compensate the number of baseCount base on type the not normals - parrtime and annotators
  if (qst.typeOfCandidate == 2) {
    countEmpty += -2;
  } else if (qst.typeOfCandidate == 3) {
    countEmpty += -2;
  }

  return { current: baseCount - countEmpty, max: baseCount };
};

export default calculateProgress;
