import React from "react";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { CheckboxWithLabel } from "formik-material-ui";
import {
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
  FormHelperText,
  Select,
  Box,
  Rating,
} from "@mui/material";
import CheckboxShowmore from "./CheckboxShowmore";
import SelectShowmore from "./SelectShowmore";
import { useFormikContext } from "formik";
import Uploader from "./Upload/Uploader";
import SelectCombo from "./SelectCombo";
import AddressAutocomplete from "./AddressAutocomplete";
import parse from "html-react-parser";
import BirthCombo from "./BirthCombo";
import { TextField as TextFieldMUI } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { SelectEnumsType } from "../../../../utils/Types/SelectEnumsType";
import { DateTime } from "luxon";

/**
const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textarea: {
    fontSize: "16px",
    padding: theme.spacing(2),
    fontFamily: "TriviaSeznam",
    width: "100%",
  },
}));
 */

interface FieldFactoryTypes {
  isDisabled: boolean;
  files?: any;
  handleFileDelete?: (
    nameOfFiles: any,
    fileId: any,
    setFieldValue: any,
    values: any,
    setSubmitting: any
  ) => void;
  handleFileUpload?: (
    filesName: string,
    values: any,
    setSubmitting: (isSubmitting: boolean) => void,
    setFieldValue: any
  ) => void;
  fields?: any[];
  filter?: any;
  selectEnums?: SelectEnumsType;
}

const FieldFactory = ({
  isDisabled,
  files,
  handleFileDelete,
  handleFileUpload,
  fields,
  filter,
  selectEnums,
}: FieldFactoryTypes) => {
  // const classes = useStyles();

  const { values, errors, touched, setFieldTouched, setFieldValue } =
    useFormikContext<any>();

  const styleVariant = "outlined";

  const handleDateChange = (itemName: string, newDate: any) => {
    setFieldValue(itemName, newDate);
  };

  const handleDateBlur = (itemName: string) => {
    setFieldTouched(itemName);
  };

  return (
    <>
      {fields?.map((item, index) => {
        let itemLabel = item.label;

        if (item.isRequired) {
          if (itemLabel) {
            itemLabel = itemLabel + " *";
          }
        }

        if (item.isVisible === false) {
          return;
        }

        //check if filter is not empty
        if (filter !== undefined) {
          //filter out fields that shouldnt be shown
          if (item.name in filter && filter[item.name] === false) {
            return "";
          }
        }

        if (item.type === "date") {
          return (
            <DatePicker
              // id="component-outlined"
              value={DateTime.fromISO(values[item.name])}
              format="d/M/yyyy"
              disabled={isDisabled}
              label={itemLabel}
              // inputVariant={styleVariant}
              openTo="year"
              // views={["year", "month","]}
              // placeholder={"22/02/2020"}
              // invalidDateMessage={errors[item.name]}
              // onBlur={() => handleDateBlur(item.name)}
              // helperText={
              //   errors[item.name] && touched[item.name]
              //     ? errors[item.name]
              //     : " "
              // }
              // error={errors[item.name] && touched[item.name] ? true : false}
              onChange={(e) => handleDateChange(item.name, e)}
            />
          );
        }

        if (item.type === "select") {
          let menuItems = item.menuItems;

          if (selectEnums !== undefined) {
            //adds menuItems fetched from DBS
            if (item.name in selectEnums) {
              menuItems = selectEnums[item.name as keyof SelectEnumsType];
            }
          }

          return (
            <FormControl
              fullWidth={item.fullWidth}
              variant={styleVariant}
              // className={classes.formControl}
              key={index}
              error={errors[item.name] && touched[item.name] ? true : false}
            >
              <InputLabel id={"select" + item.name}>{itemLabel}</InputLabel>
              <Select
                labelId={"select" + item.name}
                value={values[item.name]}
                displayEmpty
                disabled={isDisabled || item.isDisabled}
                onChange={(e) => setFieldValue(item.name, e.target.value)}
                onBlur={(e) => setFieldTouched(item.name, true)}
                label={item.label}
              >
                {menuItems.map((el: any, index2: number) => {
                  return (
                    <MenuItem key={index2 * index + 100} value={el.id}>
                      {el.text}
                    </MenuItem>
                  );
                })}
              </Select>

              <FormHelperText>
                <>
                  {errors[item.name] && touched[item.name]
                    ? errors[item.name]
                    : " "}
                </>
              </FormHelperText>
            </FormControl>
          );
        }

        if (item.type === "upload") {
          return (
            <Uploader
              key={index}
              handleFileDelete={handleFileDelete}
              handleFileUpload={handleFileUpload}
              field={item}
              files={files ? files[item.name] : []}
              deliverLaterCheckbox={item.deliverLaterCheckbox}
              isDisabled={isDisabled}
            />
          );
        }

        if (item.type === "textField") {
          return (
            <Field
              fullWidth
              key={index}
              variant={styleVariant}
              helperText=" "
              component={TextField}
              name={item.name}
              label={itemLabel}
              disabled={isDisabled || item.isDisabled}
            />
          );
        }

        if (item.type === "checkboxshowmore") {
          return (
            <Grid key={index} item>
              <CheckboxShowmore
                name={item.name}
                label={itemLabel}
                //componentToShow={item.componentToShow} />
                isDisabled={isDisabled}
                componentToShow={
                  <FieldFactory
                    isDisabled={isDisabled}
                    fields={item.componentToShow}
                  />
                }
              />
            </Grid>
          );
        }

        if (item.type === "selectshowmore") {
          let menuItems = item.menuItems;
          if (selectEnums !== undefined) {
            //adds menuItems fetched from DBS
            if (item.name in selectEnums) {
              menuItems = selectEnums[item.name as keyof SelectEnumsType];
            }
          }

          //creates an object of components that should be shown tied with their ID provied in DBS
          let optionsForSelect: any = [];

          item.componentToShow.forEach((subItem: any, index2: number) => {
            optionsForSelect.push({
              selectId: subItem.selectId,
              component: (
                <FieldFactory
                  key={index2 * index2 + 333}
                  fields={[subItem]}
                  files={files}
                  isDisabled={isDisabled}
                  handleFileDelete={handleFileDelete}
                  handleFileUpload={handleFileUpload}
                />
              ),
            });
          });

          return (
            <SelectShowmore
              key={index}
              isDisabled={isDisabled}
              field={item}
              menuItems={menuItems}
              optionsForSelect={optionsForSelect}
            />
          );
        }

        if (item.type === "typography") {
          return (
            <Grid key={index} item>
              <Box mt={item.mt} mb={item.mb}>
                <Typography variant={item.variant}>
                  {parse(item.text)}
                </Typography>
                {item.description && (
                  <Typography variant={"body1"}>
                    {parse(item.description)}
                  </Typography>
                )}
              </Box>
            </Grid>
          );
        }

        if (item.type === "dynamictypography") {
          return (
            <Grid key={index} item>
              <Typography variant={item.variant}>
                {values[item.name]}
              </Typography>
            </Grid>
          );
        }

        if (item.type === "checkbox") {
          return (
            <Grid key={index} item>
              <Field
                checked={values[item.name]}
                variant={styleVariant}
                component={CheckboxWithLabel}
                name={item.name}
                Label={{ label: itemLabel }}
                disabled={isDisabled}
                color="primary"
              />
            </Grid>
          );
        }

        if (item.type === "comboSelect") {
          let menuItems = item.menuItems;

          if (selectEnums !== undefined) {
            //adds menuItems fetched from DBS
            if (item.name in selectEnums) {
              menuItems = selectEnums[item.name as keyof SelectEnumsType];
              menuItems = menuItems.map((option: any) => {
                return {
                  text: option.text ? option.text : option.label,
                  id: option.id ? option.id : option.value,
                };
              });
            }
          }

          return (
            <Grid key={index} item>
              <SelectCombo
                field={item}
                isDisabled={isDisabled}
                menuItems={menuItems}
              />
            </Grid>
          );
        }

        if (item.type === "addressAutocomplete") {
          return (
            <Grid key={index} item>
              <AddressAutocomplete
                isDisabled={isDisabled}
                label={item.label}
                name={item.name}
                autocompleteFields={item.autocompleteFields}
              />
            </Grid>
          );
        }

        if (item.type === "birthCombo") {
          return <BirthCombo key={index} isDisabled={isDisabled} />;
        }

        if (item.type === "textarea" || item.type === "text") {
          return (
            <Box mb={2}>
              <FormControl
                fullWidth
                variant={styleVariant}
                // className={classes.formControl}
                key={index}
                error={errors[item.name] && touched[item.name] ? true : false}
              >
                <TextFieldMUI
                  id="filled-multiline-static"
                  // label="Multiline"
                  multiline
                  fullWidth
                  placeholder={item.placeholder}
                  value={values[item.name]}
                  onChange={(e) => setFieldValue(item.name, e.target.value)}
                  onBlur={() => setFieldTouched(item.name, true)}
                  rows={4}
                  // defaultValue="Default Value"
                  variant={styleVariant}
                  disabled={isDisabled}
                  error={errors[item.name] && touched[item.name] ? true : false}
                  // error={true}
                />
                <FormHelperText>
                  <>
                    {errors[item.name] && touched[item.name]
                      ? errors[item.name]
                      : " "}
                  </>
                </FormHelperText>
              </FormControl>
            </Box>
          );
        }

        if (item.type === "stars") {
          return (
            <Rating
              name="size-large"
              value={values[item.name]}
              defaultValue={1}
              disabled={isDisabled}
              onChange={(e, newValue) => {
                let valueToSave: any = newValue;
                if (typeof newValue === "number") {
                  valueToSave = newValue.toString();
                }
                setFieldValue(item.name, valueToSave);
              }}
              size="large"
            />
          );
        }

        return "";
      })}
    </>
  );
};

export default FieldFactory;
