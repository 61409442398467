import { NavLink } from "react-router-dom";
import filetableImage from "../../../image/dashboard.png";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Typography,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import BasicLayout from "../../../components/layout/BasicLayout";

interface DashboardType {
  hasLeavingQuestionnaire: boolean;
}

const Dashboard = ({ hasLeavingQuestionnaire }: DashboardType) => {
  const theme = useTheme();
  return (
    <BasicLayout>
      <Container maxWidth="lg">
        <Grid container justifyContent="center">
          <Grid size={{ xs: 12, md: 8 }}>
            <Grid size={{ xs: 12 }}>
              <Card>
                <CardMedia
                  sx={{
                    height: 170,
                    [theme.breakpoints.up("sm")]: {
                      height: 250,
                    },
                  }}
                  component="img"
                  image={filetableImage}
                  title="FileTableImage"
                />

                <CardContent
                  sx={{
                    padding: theme.spacing(3),
                  }}
                >
                  <Typography variant="h2" mb={1}>
                    Rozcestník
                  </Typography>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={3}
                  >
                    <Grid size={{ xs: 12 }}>
                      <Card variant="outlined">
                        <CardActionArea component={NavLink} to="/dotazniky">
                          <CardContent>
                            <Grid container direction="row" alignItems="center">
                              <Grid>
                                <FeedOutlinedIcon
                                  fontSize="large"
                                  sx={{
                                    position: "relative",
                                    top: "2px",
                                  }}
                                  color="primary"
                                />
                              </Grid>
                              <Grid sx={{ fontSize: "1.4em" }}>
                                Nástupní dotazníky
                              </Grid>
                            </Grid>
                            <Typography>
                              Formuláře při nástupu do práce
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>

                    {hasLeavingQuestionnaire && (
                      <Grid size={{ xs: 12 }}>
                        <Card variant="outlined">
                          <CardActionArea
                            component={NavLink}
                            to="/dotaznikvystup/1/uvod"
                          >
                            <CardContent>
                              <Grid
                                container
                                direction="row"
                                alignItems="center"
                              >
                                <Grid>
                                  <AssignmentReturnIcon
                                    sx={{
                                      position: "relative",
                                      top: "2px",
                                    }}
                                    fontSize="large"
                                    color="primary"
                                  />
                                </Grid>

                                <Grid sx={{ fontSize: "1.4em" }}>
                                  Výstupní dotazník
                                </Grid>
                              </Grid>
                              <Typography>
                                Formulář pro tvojí zpětnou vazbu
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    )}

                    <Grid size={{ xs: 12 }}>
                      <Card variant="outlined">
                        <CardActionArea component={NavLink} to="/dokumenty">
                          <CardContent>
                            <Grid container direction="row" alignItems="center">
                              <Grid>
                                <FolderOpenIcon
                                  fontSize="large"
                                  color="primary"
                                />
                              </Grid>
                              <Grid sx={{ fontSize: "1.4em" }}>Šanon</Grid>
                            </Grid>
                            <Typography>
                              Zde najdeš všechny dokumenty související s tvojí
                              prací v Seznamu.
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </BasicLayout>
  );
};

export default Dashboard;
