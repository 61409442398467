
import gql from "graphql-tag";

export const LOGIN_EXTERNAL_USER = gql`
  mutation logInExternalUser($input: LogInExternalUserInput!) {
    logInExternalUser(input: $input) {
      user {
          id
      }
      token
    }
  }
`;       

export const REGISTER_EXTERNAL_USER = gql`
  mutation registerExternalUser($input: RegisterExternalUserInput!) {
    registerExternalUser(input: $input)
  }
`;

export const RECOVER_PASSWORD = gql`
  mutation recoverPassword($email: String!) {
    recoverPassword(email: $email)
  }
`;