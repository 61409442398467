import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CircularProgressWithLabel from "../../../../components/CircularProgressWithLabel";
import { Dispatch, SetStateAction } from "react";
import { Box, Button, Toolbar, useTheme } from "@mui/material";

interface QToolbarType {
  value: number;
  setValue: Dispatch<SetStateAction<number>>;
  pages: any; //TODO: fix type
  filledNumber: number;
  numberToFill: number;
  autoUpdate: () => void;
}

/*
Has buttons for moving the questionnaire back and forward. 
Also displays filled fields of the questionnaire.
*/
const QToolbar = ({
  value,
  setValue,
  pages,
  filledNumber,
  numberToFill,
  autoUpdate,
}: QToolbarType) => {
  const theme = useTheme();

  const handleNext = () => {
    if (value === pages.length - 1) return;
    autoUpdate();
    window.scrollTo(0, 0);
    setValue(value + 1);
  };

  const handlePrev = () => {
    if (value === 0) return;
    window.scrollTo(0, 0);
    setValue(value - 1);
  };

  const calculateProgress = () => {
    return Math.floor((filledNumber / numberToFill) * 100);
  };

  return (
    <>
      <Toolbar
        disableGutters
        sx={{
          marginTop: theme.spacing(3),

          [theme.breakpoints.up("sm")]: {
            padding: 0,
          },
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Button
            variant="outlined"
            disabled={value !== 0 ? false : true}
            size="large"
            onClick={handlePrev}
            startIcon={<ArrowBackIosIcon />}
          >
            Zpět
          </Button>
        </Box>
        <Box mr={1} ml={1} textAlign="center">
          <CircularProgressWithLabel value={calculateProgress()} />
        </Box>
        <Box
          textAlign="right"
          sx={{
            flexGrow: 1,
          }}
        >
          <Button
            disabled={value !== pages.length - 1 ? false : true}
            size="large"
            color="primary"
            variant="contained"
            disableElevation
            onClick={handleNext}
            endIcon={<ArrowForwardIosIcon />}
          >
            Další
          </Button>
        </Box>
      </Toolbar>
    </>
  );
};

export default QToolbar;
