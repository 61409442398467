import {
  blue,
  green,
  grey,
  lightBlue,
  lightGreen,
  orange,
  pink,
} from "@mui/material/colors";
import {
  CANDIDATE_TYPE,
  FORM_PAGE_DICT,
  FORM_PAGE_DICT_ANNOTATOR,
  FORM_PAGE_DICT_FOREIGNER,
  FORM_PAGE_DICT_FOREIGNER_ANNOTATOR,
  FORM_PAGE_DICT_FOREIGNER_PARTTIMER,
  FORM_PAGE_DICT_PARTTIMER,
} from "../pages/Homepage/Questionnaire/Settings";
import { QuestionnaireStateType } from "./Types/QuestionnaireStateType";

//disable the questionnaire when the state is not new or returned
export const isQuestionnaireDisabled = (state: any) => {
  if (
    state === "sent" ||
    state === "inprogress" ||
    state === "done" ||
    state === "canceled"
  ) {
    return true;
  }
  return false;
};

export const QUESTIONNAIRE_STATE: any = {
  DONE: "done",
  CANCELED: "canceled",
  NEW: "new",
  INPROGRESS: "inprogress",
  RETURNED: "returned",
  SENT: "sent",
};

export const QUESTIONNAIRE_STATE_CS: { [key: string]: string } = {
  new: "Nový",
  canceled: "Zrušený",
  inprogress: "Otevřený",
  done: "Hotovo",
  returned: "Vrácený",
  sent: "Odeslaný",
};

export const FILE_FIELD_NAMES = [
  "employeeFile",
  "otherDocuments",
  "foreignerDocuments",
  "educationDocuments",
  "healthInsurancePayerDocuments",
];

export const SUBMITFLAG = {
  SEND: "sent",
  SAVE: "save",
};

//TODO: decide which method for showing/hiding fields is better, second method is in pageConfig via useEffect fucntion
//creates an object of fields which can be hidden from the questionnaire
//has to be specified manually based on fields of qustionnaire
export const getFieldFilters = (qst: any) => {
  if (!qst) return;

  let filter: any = {};

  filter["bankAccountNumber"] = qst.isBankAccountShow;
  filter["foreignerDocuments"] = qst.isForeigner;

  if (qst.typeOfCandidate === CANDIDATE_TYPE.normal) {
    filter["companyIdentificationNumber"] = false;
    filter["otherHealthInsurancePayer"] = false;
    filter["healthInsurancePayerDocuments"] = false;
  }

  if (qst.typeOfCandidate === CANDIDATE_TYPE.parttimer) {
    filter["isCriminalRecord"] = false;
    filter["isOtherEmployment"] = false;
    filter["isTradeLicense"] = false;
    filter["tradingLicenseNumber"] = false;
    filter["employeeFile"] = false;
  }

  if (qst.typeOfCandidate === CANDIDATE_TYPE.annotator) {
    filter["isCriminalRecord"] = false;
    filter["educationLevel"] = false;
    filter["educationCode"] = false;
    filter["educationDocuments"] = false;
    filter["isOtherEmployment"] = false;
    filter["isTradeLicense"] = false;
    filter["tradingLicenseNumber"] = false;
    filter["employeeFile"] = false;
  }
  return filter;
};

export const getPageDict = (qst: any) => {
  if (!qst) return;
  return getDictionary(qst.isForeigner, qst.typeOfCandidate);
};

export const getDictionary = (isForeigner: boolean, type: number) => {
  if (isForeigner) {
    switch (Number(type)) {
      case CANDIDATE_TYPE.normal:
        return FORM_PAGE_DICT_FOREIGNER;
      case CANDIDATE_TYPE.parttimer:
        return FORM_PAGE_DICT_FOREIGNER_PARTTIMER;
      case CANDIDATE_TYPE.annotator:
        return FORM_PAGE_DICT_FOREIGNER_ANNOTATOR;
    }
  } else {
    switch (Number(type)) {
      case CANDIDATE_TYPE.normal:
        return FORM_PAGE_DICT;
      case CANDIDATE_TYPE.parttimer:
        return FORM_PAGE_DICT_PARTTIMER;
      case CANDIDATE_TYPE.annotator:
        return FORM_PAGE_DICT_ANNOTATOR;
    }
  }

  return { 0: [] };
};

export const STATE_COLORS: { [key in QuestionnaireStateType]: string } = {
  new: lightBlue[100],
  sent: lightGreen[100],
  returned: orange[300],
  inprogress: blue[300],
  done: green[300],
  canceled: grey[300],
};
