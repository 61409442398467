const ForbiddenDomainList = [
  "firma.seznam.cz",
  "sz.cz",
  "televizeseznam.cz",
  "expresfm.cz",
  "mailer-daemon.firma.seznam.cz",
  "mapy.cz",
  "classicpraha.cz",
  "sreality.cz",
  "firma.stream.cz",
  "voiceofprague.cz",
  "zbozi.cz",
  "sauto.cz",
  "firmy.cz",
  "sdovolena.cz",
  "sbazar.cz",
  "info.seznam.cz",
  "o-seznam.cz",
  "prozeny.cz",
  "streamtv.cz",
  "firma.mapy.cz",
  "firma.seznam.sk",
];

export default ForbiddenDomainList;
