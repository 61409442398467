import { useFormikContext } from "formik";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const SelectCombo = (props) => {
  const { field, isDisabled, menuItems } = props;

  const { values, touched, errors, setFieldValue, setFieldTouched } =
    useFormikContext();

  let fieldLabel = field.label;

  if (field.isRequired) {
    fieldLabel = fieldLabel + " *";
  }

  const findOptionText = (id) => {
    let optionText = "";
    menuItems.forEach((el) => {
      if (el.id === id) {
        optionText = el.text;
        return;
      }
    });
    return optionText;
  };

  return (
    <>
      <Autocomplete
        value={{
          id: values[field.name],
          text: findOptionText(values[field.name]),
        }}
        onChange={(event, newValue) => {
          setFieldValue(field.name, newValue?.id || "");
        }}
        disabled={isDisabled}
        options={menuItems}
        getOptionLabel={(option) => option.text}
        renderInput={(params) => (
          <TextField
            {...params}
            onBlur={() => setFieldTouched(field.name)}
            error={touched[field.name] && errors[field.name] ? true : false}
            helperText={
              errors[field.name] && touched[field.name]
                ? errors[field.name]
                : " "
            }
            label={fieldLabel}
            variant="outlined"
          />
        )}
      />
    </>
  );
};

export default SelectCombo;
