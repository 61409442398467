import { DateTime } from "luxon";
import { QuestionnaireType } from "../../../utils/Types/QuestionnaireType";
import CryptoAES from "crypto-js/aes";

const LOCALSTORAGE_QUESTIONNAIRE_KEY = "questionnaire";
const LOCALSTORAGE_QUESTIONNAIRE_KEY_UPDATED = "questionnaire_updated";
const LOCALSTORAGE_QUESTIONNAIRE_KEY_EMAIL = "questionnaire_email";
const LOCALSTORAGE_PASS = "metrois#cool";

//fields that are not required to be filled
const optionalFields = [
  "universityTitleBefore",
  "universityTitleAfter",
  "isContactAddressDifferent",
  "isDeliverFileLater",
  "isEducationDeliverFileLater",
];

const isNumeric = (str: any) => {
  if (typeof str != "string") return false;
  // return !isNaN(str) && !isNaN(parseFloat(str));
  return !isNaN(parseFloat(str));
};

const isBirthNumberValid = (birthNumber: string) => {
  if (!birthNumber) return false;

  //removing the backslash
  birthNumber = birthNumber.replace(/\//g, "");

  //chec its number
  if (!isNumeric(birthNumber)) return false;

  const yearNumber = parseInt(birthNumber.substring(0, 2));

  //birthNumbers before year 54 had 9characters there is no control check
  if (birthNumber.length === 9 && yearNumber < 54) return true;

  if (birthNumber.length != 10) return false;

  //check division by 11
  if (parseInt(birthNumber) % 11 != 0) return false;

  return true;
};

export const questionnaireValidation = (
  vals: any,
  questionnaire: any,
  fieldFilters: any,
  filesToDelete: any
) => {
  const errors: any = {};
  const requiredLabel = "Musí být vyplněno";
  const requiredUploadLabel = "Musí být nahrán soubor";

  let fieldsNotValidate = optionalFields.slice();

  const qst = questionnaire;

  if (!qst) return;

  //adding fields that should not be validated based on questionnaire data
  for (const [key, value] of Object.entries(fieldFilters)) {
    if (!value) {
      fieldsNotValidate.push(key);
    }
  }

  Object.keys(vals).forEach((key, index) => {
    //optionalKeys
    if (fieldsNotValidate.includes(key)) return;

    /*
     *****************************
     * First page - Personal
     ******************************
     */

    //check dates
    if (key === "birthDate" || key === "idCardValidDate") {
      //check special case when the birthdate is generated if the nationality is equal 204 = Czech republic
      //then dont validate birthDate
      if (key === "birthDate" && vals["nationality"] === "204") return;

      const tmpDate = vals[key];
      //TODO: CHECK the format
      const checkDate = DateTime.fromJSDate(tmpDate);

      // const checkDate = dayjs(tmpDate);

      if (vals[key] === null || vals[key] === "") {
        errors[key] = requiredLabel;
        return;
      } else if (!checkDate.isValid) {
        errors[key] = "Špatně vyplněný formát datumu";
        return;
      }
    }

    if (key === "birthNumber") {
      if (vals[key]) {
        if (vals[key].length > 11) {
          errors[key] = "Délka rodného čísla může být max 11 znaků";
          return;
        }
      }

      if (!isBirthNumberValid(vals[key]) && vals["nationality"] === "204") {
        errors[key] = "Špatně vyplněný formát rodného čísla";
        return;
      }
    }

    if (key === "phone") {
      if (!/^(?=.*[0-9])[- +()0-9]+$/.test(vals[key])) {
        errors[key] = "Špatně zadané číslo";
      }
    }

    /*
     *****************************
     * Second page - Address
     ******************************
     */

    //validate contact address only if it is different from permanent
    if (!vals.isContactAddressDifferent) {
      switch (key) {
        case "contactAddressCity":
          return;
        case "contactAddressStreet":
          return;
        case "contactAddressPostnumber":
          return;
        default:
          break;
      }
    }

    if (key === "isContactAddressDifferent") {
      if (vals[key] === false) return;
    }

    /*
     *****************************
     * Third page - Education
     ******************************
     */

    //if user choose to deliver educationDocuments later then dont validate employeefile
    if (key === "educationDocuments" && vals["isEducationDeliverFileLater"])
      return;

    //if educationLevel is "Zakladni" or below then dont require educationCode
    if (key === "educationCode") {
      switch (vals["educationLevel"]) {
        case "7":
          return;
        case "8":
          return;
        case "9":
          return;
        default:
          break;
      }
    }

    /*
     *****************************
     * Fourth page - Social
     ******************************
     */

    //check the number of children is zero, it is also a valid value
    if (key === "children" && vals[key] === 0) {
      //then dont validate
      return;
    }

    if (key === "insuranceCompany" && vals.isNoInsuranceCompany) {
      return;
    }

    if (key === "isNoInsuranceCompany") {
      return;
    }

    if (key === "insuranceNumber" && vals.isNoInsuranceCompany) {
      return;
    }

    if (key === "idCardType") return;

    if (key === "isTradeLicense") {
      if (vals[key] === false) return;
    }

    if (key === "tradingLicenseNumber" && !vals["isTradeLicense"]) {
      return;
    }

    if (key === "isCriminalRecord") {
      if (vals[key] === false) return;
    }

    if (key === "isSeizure") {
      if (vals[key] === false) return;
    }

    if (key === "seizureNumber" && !vals["isSeizure"]) {
      return;
    }

    if (key === "children" && !vals["isSeizure"]) {
      return;
    }

    if (key === "isHandicap" || key === "isOtherEmployment") {
      if (vals[key] === null) {
        errors[key] = requiredLabel;
      } else {
        return;
      }
    }

    /*
     *****************************
     * Fifth page - Work
     ******************************
     */

    //if user has chosen the option that doesnt requires to upload employee file return
    //the number 846 is id from DBS that correspond to exact enumeration - other (ostatní)
    if (
      key === "healthInsurancePayerDocuments" &&
      vals["otherHealthInsurancePayer"] == 1
    )
      return;

    if (
      key === "companyIdentificationNumber" &&
      vals["otherHealthInsurancePayer"] != 1
    )
      return;

    if (
      key === "healthInsurancePayerDocuments" &&
      vals["otherHealthInsurancePayer"] == null
    )
      return;

    if (key === "employeeFile" && vals["employmentOrigin"] === "846") return;

    //if the value wasnt set yet
    if (key === "employeeFile" && vals["employmentOrigin"] === null) return;

    if (
      key === "employeeFile" ||
      key === "foreignerDocuments" ||
      key === "educationDocuments" ||
      key === "healthInsurancePayerDocuments"
    ) {
      //if user choose to deliver employeeFile later then dont validate employeefile
      if (key === "employeeFile" && vals["isDeliverFileLater"]) return;

      //check if some files are in questionnaire already
      if (vals[key as keyof QuestionnaireType] instanceof Array) {
        if (
          Array.isArray(qst[key as keyof QuestionnaireType]) &&
          qst[key].length > 0
        ) {
          return;
        }
      } else {
        errors[key] = requiredUploadLabel;
        return;
      }

      //check if there are any create files
      if (!(vals[key] instanceof Array)) {
        if (typeof vals[key] === "object" && "create" in vals[key]) {
          if (vals[key].create.length === 0) {
            errors[key] = requiredUploadLabel;
            return;
          }
        }
      }

      /* checks case when user deleted a file but hasnt save it to DBS,
            in that case it simulates the possible un/filled state comparing to filesToDelete
            */
      if (key in filesToDelete) {
        filesToDelete[key].forEach((fileIdDelete: any) => {
          if (vals[key] instanceof Array) {
            let futureFiles = vals[key].filter(
              (file: any) => file.id !== fileIdDelete
            );
            if (futureFiles.length === 0) {
              errors[key] = requiredLabel;
              return;
            }
          }
        });
      }
    }

    /*
     *****************************
     * Sixth page - Foreigner
     ******************************
     */

    if (qst && key === "foreignerDocuments" && !qst.isForeigner) return;

    /*
     *****************************
     * Other
     ******************************
     */

    //General check on empty
    if (!vals[key]) {
      errors[key] = requiredLabel;
    }
  });

  if (process.env.REACT_APP_ENV_TYPE !== "PRODUCTION") {
    console.log(errors);
    console.log(vals);
  }

  //saving to local storage after changes
  localStorage.setItem(LOCALSTORAGE_QUESTIONNAIRE_KEY, JSON.stringify(vals));
  localStorage.setItem(
    LOCALSTORAGE_QUESTIONNAIRE_KEY_UPDATED,
    new Date().toISOString()
  );

  //injecting email for local storage check
  localStorage.setItem(
    LOCALSTORAGE_QUESTIONNAIRE_KEY_EMAIL,
    JSON.stringify(CryptoAES.encrypt(qst.email, LOCALSTORAGE_PASS).toString())
  );

  return errors;
};
