import { useQuery } from "@apollo/client";
import { Container, IconButton, Paper, Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridEventListener,
} from "@mui/x-data-grid";
import { FILES } from "./gql";
import { useEffect, useState } from "react";
import { csCZ } from "@mui/x-data-grid/locales";
import DownloadIcon from "@mui/icons-material/Download";
import { DateTime } from "luxon";
import LoadingCircle from "../../../components/LoadingCircle";
import { useNavigate } from "react-router-dom";
import { FileTableType } from "../../../utils/Types/FileTableType";
import { translateEntityKind } from "../../../utils/FileTableUtils";
import ErrorLayout from "../../../components/layout/ErrorLayout";

interface FileTableRowsDataType extends FileTableType {
  action: any;
}

type FileTableActionType = "download";

const FileTable = () => {
  const navigate = useNavigate();

  const { loading, error, data } = useQuery(FILES);

  const [rowsData, setRowsData] = useState<FileTableRowsDataType[]>([]);

  const downloadFile = (id: number) => {
    const href = `${process.env.REACT_APP_SERVER_URL}/api/file/${id}`;
    window.open(href);
  };

  const handleAction = (
    e: React.MouseEvent,
    action: FileTableActionType,
    id: number
  ) => {
    if (action === "download") {
      downloadFile(id);
    }
    e.stopPropagation();
  };

  const handleRowClick: GridEventListener<"rowClick"> = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details // GridCallbackDetails
  ) => {
    navigate(`/dokumenty/${params.row.id}`);
    console.log(params);
  };

  useEffect(() => {
    if (data) {
      let flattenRowData: FileTableRowsDataType[] = [];

      data.fileTableAll.forEach((rowItem: any) => {
        flattenRowData.push({
          id: rowItem.id,
          createdAt: rowItem.createdAt,
          createdBy: rowItem.createdBy.username,
          owner: rowItem.owner.username,
          filename: rowItem.filename,
          entityKind: rowItem.entityKind,
          action: rowItem,
          anotherInfo: rowItem.anotherInfo,
        });
      });

      setRowsData(flattenRowData);
    }
  }, [data]);

  const columns: GridColDef[] = [
    {
      headerName: "Vytvořeno",
      field: "createdAt",
      width: 200,
      valueFormatter: (value) =>
        DateTime.fromISO(value).toFormat("d.M.yyyy HH:mm"),
    },
    { headerName: "Nahrál", field: "createdBy", width: 150 },
    { headerName: "Vlastník", field: "owner" },
    {
      headerName: "Název souboru",
      width: 200,
      field: "filename",
    },
    {
      headerName: "Druh souboru",
      width: 80,
      field: "entityKind",
      flex: 1,
      valueGetter: (value, row) =>
        translateEntityKind(data.enumerations, row.entityKind),
    },
    {
      headerName: "Akce",
      field: "action",
      renderCell: (props: GridRenderCellParams<any, FileTableRowsDataType>) => {
        if (props.value) {
          return (
            <IconButton
              onClick={(e: React.MouseEvent) =>
                handleAction(
                  e,
                  "download",
                  props.value ? parseInt(props.value.id) : -1
                )
              }
            >
              <DownloadIcon />
            </IconButton>
          );
        }

        return "";
      },
    },
  ];

  const paginationModel = { page: 0, pageSize: 10 };

  if (loading) return <LoadingCircle />;
  if (error) return <ErrorLayout errorMsg={error.message || ""} />;

  return (
    <Container>
      <Typography variant="h2" mb={2}>
        Šanon
      </Typography>
      <Paper sx={{ width: "100%" }}>
        <DataGrid
          rows={rowsData}
          columns={columns}
          initialState={{
            pagination: { paginationModel },
            sorting: { sortModel: [{ field: "createdAt", sort: "desc" }] },
          }}
          pageSizeOptions={[10, 15]}
          checkboxSelection
          sx={{
            border: 0,
            "& .MuiDataGrid-row:hover": {
              cursor: "pointer",
            },
          }}
          disableRowSelectionOnClick
          localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
          onRowClick={handleRowClick}
        />
      </Paper>
    </Container>
  );
};

export default FileTable;
