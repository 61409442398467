import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import calculateProgress from "../Questionnaire/ProgressCounter";
import LinearProgressWithLabel from "../../../components/LinearProgressWithLabel";
import { QuestionnaireType } from "../../../utils/Types/QuestionnaireType";
import { Alert, Box, Button } from "@mui/material";
import { QuestionnaireProgressType } from "../../../utils/Types/QuestionnaireProgressType";
import { GET_QUESTIONNAIRE_REQUIRED } from "./gql";
import { useQuery } from "@apollo/client";

interface QuestionnaireStateType {
  questionnaireProgress: QuestionnaireProgressType;
  questionnaireId: number;
}

const QuestionnaireState = ({
  questionnaireProgress,
  questionnaireId,
}: QuestionnaireStateType) => {
  // const [getRequiredQuestionnaire, { loading, error, data }] = useLazyQuery(
  //   GET_QUESTIONNAIRE_REQUIRED
  // );

  const {
    // loading,
    // error,
    data: qstData,
  } = useQuery(GET_QUESTIONNAIRE_REQUIRED, {
    variables: { questionnaireId: questionnaireId },
  });

  const [questionnaireProgressValue, setQuestionnaireProgressValue] =
    useState(0);

  const [qst, setQuestionnaire] = useState<QuestionnaireType | null>(null);

  const navigate = useNavigate();

  // useEffect(() => {
  //   if (questionnaireId) {
  //     getRequiredQuestionnaire({
  //       variables: {
  //         questionnaireId: questionnaireId,
  //       },
  //       onError(error) {
  //         console.log(error);
  //         // openPopupMessage(error?.message, "error");
  //       },
  //       onCompleted(data) {
  //         console.log(data);
  //         setQuestionnaire(data.questionnaire);
  //         calculateProgressUpdated(data.questionnaire);
  //       },
  //     });
  //   }
  // }, [questionnaireId]);

  useEffect(() => {
    if (qstData) {
      setQuestionnaire(qstData.questionnaire);
      //calculateProress(data.questionnaire)
      calculateProgressUpdated(qstData.questionnaire);
    }
  }, [qstData]);

  const calculateProgressUpdated = (qst: QuestionnaireType) => {
    //if the progress was already calculated by questionnaire then use the numbers in current object,
    //otherwise it will calculated the whole based on fetched questionnaire data
    if (questionnaireProgress.max !== -1) {
      const prgs = Math.floor(
        (questionnaireProgress.current / questionnaireProgress.max) * 100
      );
      setQuestionnaireProgressValue(prgs);
      return;
    }

    const { max, current } = calculateProgress(qst);

    const prg = Math.floor((current / max) * 100);
    setQuestionnaireProgressValue(prg);
  };

  const getQuestionnaireState = () => {
    if (qst) {
      if (
        qst.state === "sent" ||
        qst.state === "inprogress" ||
        qst.state === "done" ||
        qst.state === "canceled"
      ) {
        return true;
      }
    }

    return false;
  };

  //TODO: refactor
  const displayQuestionnaireStateStatus = () => {
    if (qst) {
      if (qst.state === "done") {
        return (
          <Alert severity="success">
            Dotazník byl úspěšně přijat. Vše je v pořádku.
          </Alert>
        );
      }

      if (qst.state === "sent" || qst.state === "inprogress") {
        return (
          <Alert severity="success">
            Dotaznik byl úspešně odeslán, nyní čeká na schválení.
          </Alert>
        );
      }

      if (qst.state === "returned") {
        return (
          <Alert severity="warning">
            Dotazník vám byl vrácen. Žádá si úpravy, prečtěte si komentáře.
          </Alert>
        );
      }
      if (qst.state === "canceled") {
        return <Alert severity="error">Dotazník vám byl zrušen.</Alert>;
      }
    }
  };

  if (!qst) return <>Loading...</>;

  return (
    <>
      <Box>
        Stav vyplnění dotazníku
        <LinearProgressWithLabel value={questionnaireProgressValue} />
      </Box>
      <Box mt={1}>{displayQuestionnaireStateStatus()}</Box>
      <Box mt={3} mb={5}>
        <Button
          disableElevation
          variant="contained"
          size="large"
          fullWidth
          color="primary"
          onClick={() => {
            navigate("/dotazniky/" + questionnaireId + "/formular");
          }}
        >
          {getQuestionnaireState() ? "Zobrazit dotazník" : "Vyplnit dotazník"}
        </Button>
      </Box>
    </>
  );
};

export default QuestionnaireState;
