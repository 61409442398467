import styles from './styles.module.css';

const ConsentState = ({
  children,
  style,
  className = "",
  withdrawn,
  ...props
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  withdrawn?: boolean;
  props?: any;
}) => {
  return (
    <div
      style={style}
      className={`${styles.main} ${className}`}
      {...props}
    >
      <div
        className={styles.state}
      >
        <div
          className={`${styles.state__container} ${withdrawn ? styles['state__container--withdrawn'] : ''
            }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ConsentState;
