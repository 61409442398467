import { LeavingFormValuesType } from "../../../../utils/Types/LeavingFormValuesType";

const defaultInitialValues: LeavingFormValuesType = {
  id: null,
  state: "new",
  questionnaireConfig: [],
  questionnaireResult: [],
  answer1: "",
  answer2: "",
  answer3: "",
  answer4: "",
  answer5: "",
  answer6: "",
  answer7: "",
  answer8: "",
  answer9: "",
  answer10: "",
};

export default defaultInitialValues;
