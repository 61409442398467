import React, {useEffect} from "react";
import {useFormikContext} from 'formik';

//Side effects of fields in Questionnaire
const QStore = (props) =>{

    const {qst} = props;
    const { values, validateForm, setTouched, setFieldValue } = useFormikContext();

    useEffect(()=>{
        validateForm().then(()=> {
            //submit the form when the fields are invalid so they get marked red for state of the questionnaire returned
            // if the form is valid then the user should submit the form by themselves
            if(qst.state === "returned"){
                let newTouched = {};
                Object.keys(values).forEach((key, value) => {
                    newTouched[key] = true;
                })
                setTouched(newTouched);
            }
        })
    },[])

    useEffect(()=>{
        //if nationality is Ceska republika then the insurance number sets 
        //automatically based on birthNumber
        if(values.nationality === "204" && values.birthNumber){
            setFieldValue("insuranceNumber", values.birthNumber.replace(/\//g,''))
        }

    },[values.nationality, values.birthNumber])

    return (
        <>
        </>
    )
}

export default QStore;