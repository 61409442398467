import { Route, Routes, Outlet } from "react-router-dom";
import Dashboard from "./Dashboard";
import useUserStore from "../../store/userStore";

const Homepage = () => {
  const {
    hasLeavingQuestionnaire,
  } = useUserStore();

  return (
    <>
      <Outlet />

      <Routes>
        <Route
          path="/"
          element={
            <Dashboard hasLeavingQuestionnaire={hasLeavingQuestionnaire} />
          }
        />
      </Routes>
    </>
  );
};

export default Homepage;
