import { Box, Typography } from "@mui/material";
import { DateTime } from "luxon";

const CopyrightFooter = () => {
  return (
    <Box mt={1}>
      <Typography align="center">
        Copyright © 2019-{DateTime.now().year}, Seznam.cz, a.s.
      </Typography>
    </Box>
  );
};

export default CopyrightFooter;
