import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";

import { QUESTIONNAIRE_STATE } from "../utils/QuestionnaireUtils";

interface QuestionnaireStateMessageType {
  state: string;
}

const QuestionnaireStateMessage = ({
  state,
}: QuestionnaireStateMessageType) => {
  if (state === QUESTIONNAIRE_STATE.NEW) return <></>;

  let alert: any = <></>;

  if (state === QUESTIONNAIRE_STATE.DONE) {
    alert = (
      <Alert severity="success">
        Dotazník byl úspěšně přijat. Vše je v pořádku.
      </Alert>
    );
  }

  if (state === "sent" || state === "inprogress") {
    alert = (
      <Alert severity="success">
        Dotazník byl úspěšně odeslán, nyní čeká na zpracování HR.
      </Alert>
    );
  }

  if (state === QUESTIONNAIRE_STATE.CANCELED) {
    alert = <Alert severity="error">Dotazník byl zrušen.</Alert>;
  }

  if (state === "returned") {
    alert = (
      <Alert severity="warning">
        Dotazník Ti byl vrácen a žádá si úpravy. Přečti si komentáře.
      </Alert>
    );
  }

  return (
    <Box mb={2} mt={1}>
      {alert}
    </Box>
  );
};

export default QuestionnaireStateMessage;
