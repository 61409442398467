import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Homepage from "./pages/Homepage";
import { AuthRedirect, ConsentGuard, ProtectedRoute } from "./lib/auth";
import ConsentPage from "./pages/Consent";
import QuestionnairesOverview from "./pages/Homepage/QuestionnairesOverview";
import BasicLayout from "./components/layout/BasicLayout";
import QuestionnaireForm from "./pages/Homepage/QuestionnaireForm";
import SubNavQuestionnaire from "./pages/Homepage/SubNavQuestionnaire";
import LeavingWelcome from "./pages/Homepage/LeavingQuestionnaire/LeavingWelcome";
import LeavingForm from "./pages/Homepage/LeavingQuestionnaire/LeavingForm";
import FileTable from "./pages/Homepage/FileTable";
import FileDetail from "./pages/Homepage/FileTable/FileDetail";
import AuthorizedLayout from "./components/layout/AuthorizedLayout";
import ExternalLogin from "./pages/Login/ExternalLogin";
import ExternalRegister from "./pages/Login/ExternalRegister";
import PasswordRecovery from "./pages/Login/PasswordRecovery";
import PasswordReset from "./pages/Login/PasswordReset";

const createAppRouter = () =>
  createBrowserRouter([
    {
      path: "/*",
      element: (
        <ProtectedRoute>
          <AuthorizedLayout>
            <ConsentGuard>
              <Homepage />
            </ConsentGuard>
          </AuthorizedLayout>
        </ProtectedRoute>
      ),
      children: [
        {
          path: "consent",
          element: <ConsentPage />,
        },
        {
          path: "dotazniky",
          element: <QuestionnairesOverview />,
        },
        {
          path: "dotazniky/:id/uvod",
          element: (
            <>
              <SubNavQuestionnaire mainUrl="/dotazniky" />
              <BasicLayout>
                <QuestionnaireForm />
              </BasicLayout>
            </>
          ),
        },
        {
          path: "dotazniky/:id/formular",
          element: (
            <>
              <SubNavQuestionnaire mainUrl="/dotazniky" />
              <BasicLayout>
                <QuestionnaireForm />
              </BasicLayout>
            </>
          ),
        },
        {
          path: "dotaznikvystup/:id/uvod",
          element: (
            <>
              <SubNavQuestionnaire mainUrl="/dotaznikvystup" />
              <BasicLayout>
                <LeavingWelcome />
              </BasicLayout>
            </>
          ),
        },
        {
          path: "dotaznikvystup/:id/formular",
          element: (
            <>
              <SubNavQuestionnaire mainUrl="/dotaznikvystup" />
              <BasicLayout>
                <LeavingForm />
              </BasicLayout>
            </>
          ),
        },
        {
          path: "dokumenty",
          element: (
            <>
              <BasicLayout>
                <FileTable />
              </BasicLayout>
            </>
          ),
        },
        {
          path: "dokumenty/:id",
          element: (
            <>
              <BasicLayout>
                <FileDetail />
              </BasicLayout>
            </>
          ),
        },
      ],
    },
    {
      path: "login/external",
      element: (
        <>
          <AuthRedirect />
          <ExternalLogin />
        </>
      ),
    },
    {
      path: "register/external/:token/:email",
      element: (
        <>
          <AuthRedirect />
          <ExternalRegister />
        </>
      ),
    },
    {
      path: "password/recovery",
      element: (
        <>
          <AuthRedirect />
          <PasswordRecovery />
        </>
      ),
    },
    {
      path: "password/reset/:token/:email",
      element: (
        <>
          <AuthRedirect />
          <PasswordReset />
        </>
      ),
    },
    {
      path: "*",
      element: <div>404</div>,
    },
  ]);

export const AppRouter = () => {
  const router = createAppRouter();

  return <RouterProvider router={router} />;
};
