import { Box, CircularProgress } from "@mui/material";

const LoadingCircle = () => {
  return (
    <Box display="flex">
      <Box m="auto">
        <CircularProgress />
      </Box>
    </Box>
  );
};

export default LoadingCircle;
