import Box from "@mui/material/Box";
import { ReactComponent as MetroSVG } from "../image/metro.svg";

const LoadingMetro = () => {
  return (
    <Box
      display="flex"
      //   className={classes.svgWrapper}
      sx={{
        width: "70%",
        margin: "0 auto",
      }}
    >
      <MetroSVG style={{ clipPath: "circle(20% at center)" }} />
    </Box>
  );
};

export default LoadingMetro;
