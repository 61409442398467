import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import {
  GET_QUESTIONNAIRE,
  UPDATE_QUESTIONNAIRE,
  ENUM_INSURANCE,
  ENUM_MARITAL_STATUS,
  ENUM_PREV_WORK,
  ENUM_COUNTRY,
  ENUM_EDUCATION,
} from "./gql";
import {
  Grid2 as Grid,
  Typography,
  Paper,
  Box,
  Card,
  CardContent,
} from "@mui/material";
import TabControl from "./Control/TabControl";
import Chat from "./Chat/";
import { CANDIDATE_TYPE } from "./Settings";
import UpdateSnackBar from "../../../components/UpdateSnackBar";
import LoadingCircle from "../../../components/LoadingCircle";
import QTabs from "./Control/QTabs";
import QToolbar from "./Control/QToolbar";
import PageConfig from "./PageConfig";
import calculateProgress from "../Questionnaire/ProgressCounter";
import Faq from "./Faq";
import QStore from "./QStore";
import {
  FILE_FIELD_NAMES,
  getFieldFilters,
  getPageDict,
  isQuestionnaireDisabled,
} from "../../../utils/QuestionnaireUtils";
import QuestionnaireStateMessage from "../../../components/QuestionnaireStateMessage";
import { SnackBarType } from "../../../utils/Types/SnackBarType";
import { QuestionnaireType } from "../../../utils/Types/QuestionnaireType";
import { useTheme } from "@mui/material";
import { QuestionnaireProgressType } from "../../../utils/Types/QuestionnaireProgressType";
import ErrorLayout from "../../../components/layout/ErrorLayout";
import { DateTime } from "luxon";
import { SelectEnumsType } from "../../../utils/Types/SelectEnumsType";
import { questionnaireValidation } from "./QuestionnaireValidation";
import { SubmitFlagType } from "../../../utils/Types/SubmitFlagType";
import { getInitialFormValues } from "./QuestionnaireInitialValues";

interface QuestionnairePropType {
  questionnaireProgress: QuestionnaireProgressType;
  setQuestionnaireProgress: Dispatch<SetStateAction<QuestionnaireProgressType>>;
  questionnaire: QuestionnaireType;
}

const LOCALSTORAGE_QUESTIONNAIRE_KEY = "questionnaire";
const LOCALSTORAGE_QUESTIONNAIRE_KEY_UPDATED = "questionnaire_updated";
const LOCALSTORAGE_QUESTIONNAIRE_KEY_EMAIL = "questionnaire_email";
const LOCALSTORAGE_PASS = "metrois#cool";

const Questionnaire = ({
  questionnaireProgress,
  setQuestionnaireProgress,
  questionnaire,
}: QuestionnairePropType) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [qst, setQuestionnaire] = useState<QuestionnaireType | null>(null);
  const [pageIndex, setPageIndex] = useState<number>(0);

  //Controls the appereance of loading cirlce while saving the questionnaire
  const [isSaving, setIsSaving] = useState(false);
  const [savingInfoDate, setSavingInfoDate] = useState("");

  const [snackBar, setSnackBar] = useState<SnackBarType>({
    open: false,
    message: "",
    severity: "info",
  });

  const [filesToDelete, setFilesToDelete] = useState<any>([]);

  const { data: insuranceCompanyEnum, error: insuranceCompanyEnumError } =
    useQuery(ENUM_INSURANCE);
  const { data: maritalStatusEnum, error: maritalStatusEnumError } =
    useQuery(ENUM_MARITAL_STATUS);
  const { data: previousWorkEnum, error: previousWorkEnumError } =
    useQuery(ENUM_PREV_WORK);
  const { data: countryEnum, error: countryEnumError } = useQuery(ENUM_COUNTRY);
  const { data: educationEnum, error: educationEnumError } =
    useQuery(ENUM_EDUCATION);

  const [updateQuestionnaire, { data: updateData, error: updateDataError }] =
    useMutation(UPDATE_QUESTIONNAIRE, {
      update: (cache, updatedQuestionnaire) => {
        cache.writeQuery({
          query: GET_QUESTIONNAIRE,
          data: {
            questionnaire: updatedQuestionnaire.data.questionnaireUpdateClient,
          },
        });
      },
      onCompleted: (updatedData) => {
        setQuestionnaire(updatedData.questionnaireUpdateClient);

        const savingDate = DateTime.now();
        const savingPrefix = "Automaticky uloženo ";
        const savingText =
          savingPrefix + savingDate.toFormat("d.M.yyyy HH:mm:ss");
        const savingTime = savingDate.toFormat("HH:mm:ss");

        setSavingInfoDate(savingText);

        //is autoSaving when user press Next button
        if (isSaving) {
          setSnackBar({
            open: true,
            message: savingPrefix + savingTime,
            severity: "success",
          });
        } else {
          //is saving a file
          setSnackBar({ open: true, message: "Uloženo", severity: "success" });
        }
      },
    });

  const [submitQuestionnaire, { data: submitedData, error: submitDataError }] =
    useMutation(UPDATE_QUESTIONNAIRE, {
      update(cache, updatedQuestionnaire) {
        cache.writeQuery({
          query: GET_QUESTIONNAIRE,
          data: {
            questionnaire: updatedQuestionnaire.data.questionnaireUpdateClient,
          },
        });
      },
    });

  useEffect(() => {
    if (questionnaire) {
      setQuestionnaire(questionnaire);
      // if (process.env.REACT_APP_ENV_TYPE !== "PRODUCTION") {
      //   console.log(data);
      //   console.log(questionnaireProgress);
      // }
    }
  }, [questionnaire]);

  useEffect(() => {
    if (updateData) {
      if (process.env.REACT_APP_ENV_TYPE !== "PRODUCTION") {
        console.log("after update");
        console.log(updateData.questionnaireUpdateClient);
      }
    }
  }, [updateData]);

  useEffect(() => {
    if (submitedData) {
      setSnackBar({
        open: true,
        message: "Úspěšně odesláno",
        severity: "success",
      });
      navigate("/");
      setQuestionnaire(submitedData.questionnaireUpdateClient);

      if (process.env.REACT_APP_ENV_TYPE !== "PRODUCTION") {
        console.log("after submit");
        console.log(submitedData.questionnaireUpdateClient);
      }
    }
  }, [submitedData]);

  const validation = (vals: any) => {
    const validationResult = questionnaireValidation(
      vals,
      qst,
      getFieldFilters(qst),
      filesToDelete
    );

    calculateProgressNumbers(vals);

    return validationResult;
  };

  //handles save and submit of questionnaire
  const handleSubmit = (
    vals: any,
    flag: SubmitFlagType,
    setSubmitting: any,
    setFieldValue?: any
  ) => {
    if (!qst) return;

    //FIXME: this needs to be allowed, uncommented
    setSubmitting(true);

    const fileNames = ["otherDocuments", "employeeFile"];

    const questionnaireUpdateInput = JSON.parse(JSON.stringify(vals));

    questionnaireUpdateInput.id = questionnaire.id;

    //adding foreigner documents field for upload only if user is foreigner otherwise delete it
    if (qst.isForeigner) {
      fileNames.push("foreignerDocuments");
    } else {
      delete questionnaireUpdateInput["foreignerDocuments"];
    }

    //add healthInsurancePayerDocuments only if its annotator or partimer
    if (
      qst.typeOfCandidate === CANDIDATE_TYPE.parttimer ||
      qst.typeOfCandidate === CANDIDATE_TYPE.annotator
    ) {
      fileNames.push("healthInsurancePayerDocuments");
    } else {
      delete questionnaireUpdateInput["healthInsurancePayerDocuments"];
    }

    //add for normal and par timer
    if (
      qst.typeOfCandidate === CANDIDATE_TYPE.normal ||
      qst.typeOfCandidate === CANDIDATE_TYPE.parttimer
    ) {
      fileNames.push("educationDocuments");
    } else {
      delete questionnaireUpdateInput["educationDocuments"];
    }

    fileNames.forEach((fileName) => {
      questionnaireUpdateInput[fileName] = { create: [], delete: [] };

      if ("delete" in vals[fileName]) {
        if (!(vals[fileName].delete.length > 0)) {
          questionnaireUpdateInput[fileName].delete = [];
        } else {
          questionnaireUpdateInput[fileName].delete = vals[fileName].delete;
        }
      } else {
        questionnaireUpdateInput[fileName].delete = [];
      }

      if ("create" in vals[fileName]) {
        if (!(vals[fileName].create.length > 0)) {
          questionnaireUpdateInput[fileName].create = [];
        } else {
          questionnaireUpdateInput[fileName].create = vals[fileName].create;
        }
      } else {
        questionnaireUpdateInput[fileName].create = [];
      }
    });

    questionnaireUpdateInput["birthDate"] = DateTime.fromISO(
      questionnaireUpdateInput["birthDate"]
    ).isValid
      ? DateTime.fromISO(questionnaireUpdateInput["birthDate"])
      : null;

    delete questionnaireUpdateInput["idCardType"];
    delete questionnaireUpdateInput["agreement"];
    delete questionnaireUpdateInput["email"];

    if (flag === "sent") {
      let fileActions = { create: [], delete: [] };

      FILE_FIELD_NAMES.forEach((name) => {
        questionnaireUpdateInput[name] = fileActions;
      });

      questionnaireUpdateInput["state"] = "sent";
      submitQuestionnaire({ variables: { input: questionnaireUpdateInput } });
      setSubmitting(false);
    } else if (flag === "update") {
      console.log("SENDING SAVE");
      console.log(questionnaireUpdateInput);
      updateQuestionnaire({ variables: { input: questionnaireUpdateInput } })
        .catch((err) => {
          setSnackBar({ open: true, message: err.message, severity: "error" });
        })
        .then(() => {
          //Cleans the values in Formik so when click on save button after upload it doesnt upload twice
          FILE_FIELD_NAMES.forEach((name) => {
            if (
              !questionnaireUpdateInput[name] ||
              !questionnaireUpdateInput[name].create
            )
              return;

            if (questionnaireUpdateInput[name].create.length > 0) {
              setFieldValue(name, [{ id: 1 }]);
            }
          });
          setSubmitting(false);
          setIsSaving(false);
        });
    }
  };

  //deletes files from questionnaire
  const handleFileDelete = (
    nameOfFiles: any,
    fileId: any,
    setFieldValue: any,
    values: any,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    if (!qst) return;

    let tmpFiles = qst[nameOfFiles as keyof QuestionnaireType];
    let newFiles: any = [];

    if (Array.isArray(tmpFiles)) {
      newFiles = tmpFiles.filter((item: any) => {
        return item.id !== fileId;
      });
    }

    //if nameOfFiles hasnt been initilize then create a new array for fileIds
    if (!(nameOfFiles in filesToDelete)) {
      filesToDelete[nameOfFiles] = [];
      filesToDelete[nameOfFiles].push(fileId);
    } else {
      filesToDelete[nameOfFiles].push(fileId);
    }

    setFilesToDelete(filesToDelete);

    let fileActions: any = { create: [], delete: [] };

    FILE_FIELD_NAMES.forEach((name: any) => {
      if (name === nameOfFiles) return;
      values[name] = qst[name as keyof QuestionnaireType];
    });

    fileActions.delete.push(fileId);
    values[nameOfFiles] = fileActions;

    setFieldValue(nameOfFiles, newFiles);

    handleSubmit(values, "update", setSubmitting, setFieldValue);
  };

  const handleFileUpload = (
    filesName: string,
    values: any,
    setSubmitting: (isSubmitting: boolean) => void,
    setFieldValue: any
  ) => {
    let fileActions = { create: [], delete: [] };

    FILE_FIELD_NAMES.forEach((name) => {
      if (name === filesName) return;
      values[name] = fileActions;
    });

    handleSubmit(values, "update", setSubmitting, setFieldValue);
  };

  const getSelectEnums = (): SelectEnumsType => {
    const sortedCountryEnums = countryEnum.enumerations
      .slice()
      .sort((a: any, b: any) => (a.value > b.value ? 1 : -1));
    return {
      insuranceCompany: insuranceCompanyEnum.enumerations,
      maritalStatus: maritalStatusEnum.enumerations,
      employmentOrigin: previousWorkEnum.enumerations,
      nationality: sortedCountryEnums,
      educationLevel: educationEnum.enumerations,
      otherHealthInsurancePayer: [
        { id: 1, text: "Jiný zaměstnavatel" },
        { id: 2, text: "Studium" },
        { id: 3, text: "ZTP" },
        { id: 4, text: "Starobní/Invalidní důchod" },
        { id: 5, text: "Péče o dítě" },
        { id: 6, text: "Jiné" },
      ],
    };
  };

  //dynamically calculates number of fields that should filled
  const calculateProgressNumbers = (vals: any) => {
    if (!qst) return;

    const injectAdditionalData = {
      isForeigner: qst.isForeigner,
      typeOfCandidate: qst.typeOfCandidate,
      isBankAccountShow: qst.isBankAccountShow,
    };

    const { max, current } = calculateProgress({
      ...vals,
      ...injectAdditionalData,
    });
    setQuestionnaireProgress({ current: current, max: max });
  };

  const initialFormValues = getInitialFormValues(qst);

  const handleSnackBarClose = () => {
    setSnackBar({ open: false, message: "", severity: "info" });
  };

  const autoUpdate = (values: any, setSubmitting: any, setFieldValue: any) => {
    if (qst && !isQuestionnaireDisabled(qst.state)) {
      handleSubmit(values, "update", setSubmitting, setFieldValue);
      setIsSaving(true);
    }
  };

  if (updateDataError)
    return <ErrorLayout errorMsg={updateDataError.message} />;
  if (submitDataError)
    return <ErrorLayout errorMsg={submitDataError.message} />;
  if (insuranceCompanyEnumError)
    return <ErrorLayout errorMsg={insuranceCompanyEnumError.message} />;
  if (maritalStatusEnumError)
    return <ErrorLayout errorMsg={maritalStatusEnumError.message} />;
  if (previousWorkEnumError)
    return <ErrorLayout errorMsg={previousWorkEnumError.message} />;
  if (countryEnumError)
    return <ErrorLayout errorMsg={countryEnumError.message} />;
  if (educationEnumError)
    return <ErrorLayout errorMsg={educationEnumError.message} />;

  if (!qst) return <LoadingCircle />;
  if (qst && Object.keys(qst).length === 0) return <LoadingCircle />;
  if (maritalStatusEnum === undefined) return <LoadingCircle />;
  if (insuranceCompanyEnum === undefined) return <LoadingCircle />;
  if (previousWorkEnum === undefined) return <LoadingCircle />;
  if (countryEnum === undefined) return <LoadingCircle />;
  if (educationEnum === undefined) return <LoadingCircle />;

  const pageProps = {
    qst: qst,
    questionnaireState: isQuestionnaireDisabled(qst?.state || ""),
    fieldFilter: getFieldFilters(qst),
    pageDict: getPageDict(qst),
    selectEnums: getSelectEnums(),
    setPageIndex: setPageIndex,
    handleFileDelete: handleFileDelete,
    handleFileUpload: handleFileUpload,
  };

  return (
    <>
      <Formik
        initialValues={initialFormValues}
        validate={validation}
        onSubmit={(values: any, extraFunctions: any) =>
          handleSubmit(values, "sent", extraFunctions.setSubmitting, null)
        }
      >
        {({ values, errors, touched, setSubmitting, setFieldValue }: any) => {
          //definition of of each page - fields, icons etc.
          const pageConfig = PageConfig({
            ...pageProps,
            values,
            errors,
            touched,
            setFieldValue,
          });

          return (
            <>
              <QStore qst={qst} />

              <Grid spacing={4} container>
                <Grid size={{ xs: 12, md: 7 }}>
                  <Box
                    sx={{
                      position: "sticky",
                      top: "56px",
                      zIndex: "1",
                    }}
                  >
                    <Paper>
                      <QTabs
                        value={pageIndex}
                        handleChangePageIndex={setPageIndex}
                        pages={pageConfig}
                      />
                    </Paper>
                  </Box>

                  <Card>
                    <CardContent>
                      <QuestionnaireStateMessage state={qst?.state || ""} />

                      <Grid container>
                        <Grid size={{ xs: 12 }}>
                          <Form>
                            <Box
                              sx={{
                                "& .MuiTextField-root": {
                                  marginTop: theme.spacing(1),
                                  marginBottom: theme.spacing(1),
                                },

                                "& .MuiFormControl-root": {
                                  width: "100%",
                                },
                              }}
                            >
                              <TabControl
                                setValue={setPageIndex}
                                value={pageIndex}
                                pages={pageConfig}
                              />
                            </Box>
                          </Form>
                        </Grid>
                        {/* <DevTools/> */}
                      </Grid>
                      <QToolbar
                        value={pageIndex}
                        setValue={setPageIndex}
                        pages={pageConfig}
                        numberToFill={questionnaireProgress.max}
                        filledNumber={questionnaireProgress.current}
                        autoUpdate={() =>
                          autoUpdate(values, setSubmitting, setFieldValue)
                        }
                      />

                      <Typography
                        variant="body2"
                        sx={{
                          color: "#909090",
                          marginTop: theme.spacing(2),
                        }}
                      >
                        {savingInfoDate}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid size={{ xs: 12, md: 5 }}>
                  <Box mb={3}>
                    <Card>
                      <CardContent>
                        <Chat />
                      </CardContent>
                    </Card>
                  </Box>

                  <Box mb={3}>
                    <Card>
                      <CardContent>
                        <Faq setPageIndex={setPageIndex} pages={pageConfig} />
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
              </Grid>
              <UpdateSnackBar
                message={snackBar.message}
                onClose={handleSnackBarClose}
                isOpen={snackBar.open}
                severity={snackBar.severity}
              />
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default Questionnaire;
