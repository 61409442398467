import { Dispatch, SetStateAction } from "react";
import { QuestionnaireProgressType } from "../../../../utils/Types/QuestionnaireProgressType";
import { QuestionnaireType } from "../../../../utils/Types/QuestionnaireType";
import Questionnaire from "../../Questionnaire";

interface FormType {
  questionnaire: QuestionnaireType;
  questionnaireProgress: QuestionnaireProgressType;
  setQuestionnaireProgress: Dispatch<SetStateAction<QuestionnaireProgressType>>;
}

const Form = ({
  questionnaire,
  setQuestionnaireProgress,
  questionnaireProgress,
}: FormType) => {
  return (
    <Questionnaire
      questionnaire={questionnaire}
      questionnaireProgress={questionnaireProgress}
      setQuestionnaireProgress={setQuestionnaireProgress}
    />
  );
};

export default Form;
