import {
  Card,
  CardActionArea,
  CardContent,
  Grid2 as Grid,
  Typography,
} from "@mui/material";
import { QuestionnaireType } from "../../../utils/Types/QuestionnaireType";
import { useNavigate } from "react-router-dom";
import StateLabel from "../../../components/StateLabel";
import { DateTime } from "luxon";

interface QuestionnaireEntryType {
  questionnaire: QuestionnaireType;
}
const QuestionnaireEntry = ({ questionnaire }: QuestionnaireEntryType) => {
  let navigate = useNavigate();

  const handleClickQuestionnaire = () => {
    navigate("/dotazniky/" + questionnaire.id + "/uvod");
  };

  return (
    <>
      <Card variant="outlined">
        <CardActionArea onClick={handleClickQuestionnaire}>
          <CardContent>
            <Grid container justifyItems="center">
              <Grid sx={{ flex: 1 }}>
                {questionnaire.jobTitle ? (
                  <Typography variant="h3">{questionnaire.jobTitle}</Typography>
                ) : (
                  <Typography variant="h3">Dotazník</Typography>
                )}
              </Grid>
              <Grid>
                <StateLabel state={questionnaire.state} />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <Typography variant="subtitle1">
                  {questionnaire.typeOfEmployment}
                </Typography>
                <br />
                Datum nástupu:{" "}
                <b>
                  {DateTime.fromISO(questionnaire.startDate).isValid ? (
                    DateTime.fromISO(questionnaire.startDate).toFormat(
                      "d.M.yyyy"
                    )
                  ) : (
                    <i>neuvedeno</i>
                  )}
                </b>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

export default QuestionnaireEntry;
