import { gql } from "@apollo/client";

export const GET_POSTS = gql`
  query questionnaire($questionnaireId: Float!) {
    questionnaire(questionnaireId: $questionnaireId) {
      chat {
        id
        posts {
          text
          createdAt
          createdBy {
            username
          }
        }
      }
    }
  }
`;

export const SEND_POST = gql`
  mutation postCreate($text: String!, $chatId: Int!) {
    postCreate(text: $text, chatId: $chatId) {
      id
      text
      createdBy {
        username
      }
      createdAt
    }
  }
`;
