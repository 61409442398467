import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid2 as Grid,
  Typography,
} from "@mui/material";
import parse from "html-react-parser";
import { QuestionnaireType } from "../../../../utils/Types/QuestionnaireType";
import { QuestionnaireProgressType } from "../../../../utils/Types/QuestionnaireProgressType";
import QuestionnaireState from "../QuestionnaireState";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";

interface IntroductionType {
  questionnaire: QuestionnaireType;
  questionnaireProgress: QuestionnaireProgressType;
}

const Introduction = ({
  questionnaire,
  questionnaireProgress,
}: IntroductionType) => {
  return (
    <Grid container>
      <Grid size={{ xs: 12 }}>
        <Typography variant="h2" mb={2}>
          <FeedOutlinedIcon
            sx={{ fontSize: "1em", position: "relative", top: "5px" }}
            color="primary"
          />
          Dotazník {questionnaire.jobTitle}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Card>
          <CardContent>
            <QuestionnaireState
              questionnaireProgress={questionnaireProgress}
              questionnaireId={parseInt(questionnaire.id)}
            />
            <Box>
              {questionnaire.customText && parse(questionnaire.customText)}
              {questionnaire.customText2 && parse(questionnaire.customText2)}
              <Divider />
              {questionnaire.customText3 && parse(questionnaire.customText3)}
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Introduction;
