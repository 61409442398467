import { Container } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_QUESTIONNAIRE } from "./gql";
import Introduction from "./Introduction";
import { useEffect, useState } from "react";
import { QuestionnaireProgressType } from "../../../utils/Types/QuestionnaireProgressType";
import Form from "./Form";
import LoadingCircle from "../../../components/LoadingCircle";
import ErrorLayout from "../../../components/layout/ErrorLayout";

type ShowDetailType = "introduction" | "form";

const QuestionnaireForm = () => {
  const { id } = useParams<{ id: string }>();
  let location = useLocation();

  const [showDetail, setShowDetail] = useState<ShowDetailType>("introduction");

  const [questionnaireProgress, setQuestionnaireProgress] =
    useState<QuestionnaireProgressType>({
      max: -1,
      current: -1,
    });

  const {
    data: qstData,
    loading: qstLoading,
    error: qstError,
  } = useQuery(GET_QUESTIONNAIRE, {
    variables: {
      questionnaireId: id ? parseInt(id) : "",
    },
  });

  useEffect(() => {
    if (location.pathname.includes("/uvod")) {
      setShowDetail("introduction");
    } else {
      setShowDetail("form");
    }
  }, [location.pathname]);

  useEffect(() => {
    console.log(qstData);
  }, [qstData]);

  if (qstLoading) return <LoadingCircle />;
  if (qstError) return <ErrorLayout errorMsg={qstError.message || ""} />;

  return (
    <Container maxWidth="md">
      {showDetail === "introduction" && (
        <Introduction
          questionnaire={qstData.questionnaire}
          questionnaireProgress={questionnaireProgress}
        />
      )}

      {showDetail === "form" && (
        <Form
          questionnaire={qstData.questionnaire}
          questionnaireProgress={questionnaireProgress}
          setQuestionnaireProgress={setQuestionnaireProgress}
        />
      )}
    </Container>
  );
};

export default QuestionnaireForm;
