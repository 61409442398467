import gql from "graphql-tag";

export const FILES = gql`
  query fileTableAll {
    fileTableAll {
      id
      createdBy {
        id
        username
      }
      owner {
        id
        username
      }
      createdAt
      filename
      entityKind
      anotherInfo
    }
    enumerations(type: DOKUMENTY) {
      id
      text
      value
    }
  }
`;

export const GET_FILE = gql`
  query fileTableSingle($id: Float!) {
    fileTableSingle(id: $id) {
      id
      createdBy {
        id
        username
      }
      owner {
        id
        username
      }
      createdAt
      filename
      entityKind
      anotherInfo
    }
    enumerations(type: DOKUMENTY) {
      id
      text
      value
    }
  }
`;

export const SEND_POST = gql`
  mutation postCreate($text: String!, $chatId: Int!) {
    postCreate(text: $text, chatId: $chatId) {
      id
      text
      createdBy {
        email
      }
      createdAt
    }
  }
`;
