import { Dispatch, SetStateAction } from "react";
import { Tabs, Tab, useTheme } from "@mui/material";

interface QTabsType {
  value: number;
  handleChangePageIndex: Dispatch<SetStateAction<number>>;
  pages: any;
}

const QTabs = ({ value, handleChangePageIndex, pages }: QTabsType) => {
  const theme = useTheme();

  return (
    <Tabs
      value={value}
      onChange={(e, value) => handleChangePageIndex(value)}
      indicatorColor="primary"
      textColor="primary"
      variant="fullWidth"
    >
      {pages.map((item: any, index: number) => {
        return (
          <Tab
            sx={{
              minWidth: "20px",
              fontSize: "0.6rem",
              [theme.breakpoints.up("sm")]: {
                fontSize: "0.75rem",
              },
            }}
            key={index}
            icon={item.icon}
            label={item.pageLabel}
            wrapped={true}
          />
        );
      })}
    </Tabs>
  );
};

export default QTabs;
