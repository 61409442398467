import { Alert, Box, Button } from "@mui/material";

interface ErrorLayoutType {
  errorMsg: string;
}

const ErrorLayout = ({ errorMsg }: ErrorLayoutType) => {
  const handleReload = () => {
    window.location.reload();
  };

  return (
    <Box padding={3}>
      <Box mb={1}>
        <Box sx={{ typography: "h5" }}>Ups nastala chyba</Box>
      </Box>
      <Alert severity="error">{errorMsg}</Alert>
      <Box mt={2}>
        <Button
          onClick={() => handleReload()}
          variant="contained"
          color="primary"
        >
          Obnovit
        </Button>
      </Box>
    </Box>
  );
};

export default ErrorLayout;
