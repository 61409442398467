import { NavLink, useParams } from "react-router-dom";

import { Button, Container, Grid2 as Grid, Toolbar } from "@mui/material";

interface SubNavQuestionnaireType {
  mainUrl: string;
}

const SubNavQuestionnaire = ({ mainUrl }: SubNavQuestionnaireType) => {
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <Toolbar
        sx={{
          minHeight: "46px",
          backgroundColor: "#FFFFFF",
          boxShadow:
            "0 2px 32px 0 rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.03);",
        }}
      >
        <Container>
          <Grid container direction="row" justifyContent="center" spacing={2}>
            <Grid>
              <NavLink to={mainUrl + "/" + id + "/uvod"}>
                <Button sx={{ color: "black" }}>Úvod</Button>
              </NavLink>
            </Grid>
            <Grid>
              <NavLink to={mainUrl + "/" + id + "/formular"}>
                <Button sx={{ color: "black" }}>Formulář</Button>
              </NavLink>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </>
  );
};

export default SubNavQuestionnaire;
