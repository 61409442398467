import { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import {
  Typography,
  TextField,
  Grid,
  makeStyles,
  Autocomplete,
} from "@mui/material";
import throttle from "lodash/throttle";
import LocationOnIcon from "@mui/icons-material/LocationOn";

/**
const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));
 */

const AddressAutocomplete = (props) => {
  const { name, autocompleteFields, label, isDisabled } = props;

  const { values, errors, touched, setFieldValue, setFieldTouched } =
    useFormikContext();

  const [suggestedAddress, setSuggestedAddress] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // const classes = useStyles();

  const fetchSuggested = throttle(() => {
    //fetch('https://mapy.cz/suggest/?count=5&phrase='+ values[name] +'%202&category=address_cz,street_cz,municipality_cz')
    fetch(
      "https://mapy.cz/suggest/?count=5&phrase=" +
        values[name] +
        "%202&category=address_cz"
    )
      .then((response) => response.json())
      .then((data) => {
        setSuggestedAddress(data.result);
      });
  }, 100);

  useEffect(() => {
    // fetchSuggested();

    if (suggestedAddress.length === 0) {
      setIsPopupOpen(false);
      return;
    }
  }, [values[name]]);

  return (
    <>
      <Autocomplete
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.userData.suggestFirstRow
        }
        filterOptions={(x) => x}
        options={suggestedAddress}
        disabled={isDisabled}
        filterSelectedOptions
        value={values[name]}
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        noOptionsText="Adresa nenalezena"
        onChange={(event, newValue) => {
          if (!newValue) return;

          setFieldValue(name, newValue.userData.suggestFirstRow);
          setFieldValue(
            autocompleteFields.city,
            newValue.userData.municipality
          );
          setFieldValue(
            autocompleteFields.postNumber,
            newValue.userData.zipCode
          );
        }}
        onInputChange={(event, newInputValue) => {
          setFieldValue(name, newInputValue);
          if (values[name]) {
            setIsPopupOpen(true);
          } else {
            setIsPopupOpen(false);
          }
        }}
        autoHighlight={true}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            onBlur={() => setFieldTouched(name)}
            error={touched[name] && errors[name] ? true : false}
            helperText={errors[name] && touched[name] ? errors[name] : " "}
            variant="outlined"
            fullWidth
          />
        )}
        renderOption={(option) => {
          const streetText = option.userData.suggestFirstRow;
          const streetTextSecond = option.userData.suggestSecondRow;

          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon
                // className={classes.icon}
                />
              </Grid>
              <Grid item xs>
                {streetText}
                <Typography variant="body2" color="textSecondary">
                  {streetTextSecond}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
    </>
  );
};

export default AddressAutocomplete;
