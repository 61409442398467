import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import useConsentStore, { ConsentType } from "../../store/consentStore";
import useUpdateConsentOwnHands from "../../hooks/useUpdateConsentOwnHands";
import { useState } from "react";

const ConsentOnlineCancel = () => {
  const {
    consentOwnHandsText,
  } = useConsentStore();

  const { updateConsent } = useUpdateConsentOwnHands();

  const [open, setOpen] = useState(false);

  const handleDeleteConsentOwnHands = () => {
    if (consentOwnHandsText?.id) {
      updateConsent(consentOwnHandsText.id, ConsentType.WITHDRAWAL);
    }
  }

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant="outlined"
        color="primary"
        disableElevation
      >
        Odvolat
      </Button>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>
          Opravdu chcete odvolat souhlas?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tímto krokem odvoláte souhlas s elektronickou komunikací.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteConsentOwnHands}>Ano</Button>
          <Button onClick={() => setOpen(false)} autoFocus>
            Ne
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConsentOnlineCancel;
