import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  FIELD_GROUP_1,
  FIELD_GROUP_2,
  FIELD_GROUP_2_B,
  FIELD_GROUP_3,
  FIELD_GROUP_4,
  FIELD_GROUP_5,
  FIELD_GROUP_5_FOREIGNER,
  FIELD_GROUP_4_ANNOTATOR,
} from "./Settings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FieldFactory from "./FieldComponents/FieldFactory";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import DescriptionIcon from "@mui/icons-material/Description";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SchoolIcon from "@mui/icons-material/School";
import FlareIcon from "@mui/icons-material/Flare";
import { Badge } from "@mui/material";
import RecapPage from "./RecapPage";
import { SelectEnumsType } from "../../../utils/Types/SelectEnumsType";

//defining content of each questionnaire page
interface PageConfigType {
  qst: any;
  questionnaireState: any;
  fieldFilter: any;
  pageDict: any;
  selectEnums: SelectEnumsType;
  setFieldValue: any;
  setPageIndex: Dispatch<SetStateAction<number>>;
  handleFileDelete: (
    nameOfFiles: any,
    fileId: any,
    setFieldValue: any,
    values: any,
    setSubmitting: any
  ) => void;
  handleFileUpload: (
    filesName: string,
    values: any,
    setSubmitting: (isSubmitting: boolean) => void,
    setFieldValue: any
  ) => void;
  errors: any;
  touched: any;
  values: any;
}

const PageConfig = ({
  qst,
  questionnaireState,
  fieldFilter,
  pageDict,
  setFieldValue,
  selectEnums,
  setPageIndex,
  handleFileDelete,
  handleFileUpload,
  errors,
  touched,
  values,
}: PageConfigType) => {
  let badgeCount: any = {};

  const isDisabled = questionnaireState;

  const [fieldGroup2B, setFieldGroup2B] = useState(FIELD_GROUP_2_B);
  const [fieldGroup3, setFieldGroup3] = useState(FIELD_GROUP_3);

  const files = {
    employeeFile: qst.employeeFile,
    otherDocuments: qst.otherDocuments,
    foreignerDocuments: qst.foreignerDocuments,
    educationDocuments: qst.educationDocuments,
    healthInsurancePayerDocuments: qst.healthInsurancePayerDocuments,
  };

  //check the level of education, if its too low then disable the educationCode field
  useEffect(() => {
    if (
      values.educationLevel === "9" ||
      values.educationLevel === "8" ||
      values.educationLevel === "7"
    ) {
      let newFieldGroup = changeFieldPropertyValue(
        fieldGroup2B,
        "educationCode",
        "isDisabled",
        true
      );
      // newFieldGroup = changeFieldPropertyValue(newFieldGroup, "educationCode", "isRequired", false);
      //reset to empty if it was modified
      setFieldValue("educationCode", "");
      setFieldGroup2B(newFieldGroup);
    } else {
      let newFieldGroup = changeFieldPropertyValue(
        fieldGroup2B,
        "educationCode",
        "isDisabled",
        false
      );
      // newFieldGroup = changeFieldPropertyValue(newFieldGroup, "educationCode", "isRequired", true);
      setFieldGroup2B(newFieldGroup);
    }
  }, [values.educationLevel]);

  useEffect(() => {
    if (qst) {
      if (qst.isForeigner === true) {
        const newFieldGroup = changeFieldPropertyValue(
          fieldGroup3,
          "isNoInsuranceCompany",
          "isVisible",
          true
        );
        setFieldGroup3(newFieldGroup);
      }
    }
  }, [qst]);

  //No Insurance Company Side Effects
  useEffect(() => {
    let newFieldGroup = changeFieldPropertyValue(
      fieldGroup3,
      "insuranceCompany",
      "isDisabled",
      values.isNoInsuranceCompany
    );
    newFieldGroup = changeFieldPropertyValue(
      fieldGroup3,
      "insuranceCompany",
      "isRequired",
      !values.isNoInsuranceCompany
    );
    newFieldGroup = changeFieldPropertyValue(
      fieldGroup3,
      "insuranceNumber",
      "isDisabled",
      values.isNoInsuranceCompany
    );
    newFieldGroup = changeFieldPropertyValue(
      fieldGroup3,
      "insuranceNumber",
      "isRequired",
      !values.isNoInsuranceCompany
    );
    setFieldGroup3(newFieldGroup);

    //reset to empty if it was modified
    if (values.isNoInsuranceCompany) {
      setFieldValue("insuranceCompany", "");
      setFieldValue("insuranceNumber", "");
    }
  }, [values.isNoInsuranceCompany]);

  const changeFieldPropertyValue = (
    fieldGroup: any,
    fieldName: string,
    property: any,
    value: any
  ) => {
    fieldGroup.some((field: any) => {
      if (field.name === fieldName) {
        field[property] = value;
        return true;
      } else {
        return false;
      }
    });

    return fieldGroup;
  };

  let pagesDatabase = [
    {
      pageLabel: "Osobní",
      page: (
        <FieldFactory
          isDisabled={isDisabled}
          selectEnums={selectEnums}
          fields={FIELD_GROUP_1}
        />
      ),
      icon: <AccountCircleIcon />,
    },
    {
      pageLabel: "Adresa",
      page: <FieldFactory isDisabled={isDisabled} fields={FIELD_GROUP_2} />,
      icon: <LocationOnIcon />,
    },
    {
      pageLabel: "Vzdělání",
      page: (
        <FieldFactory
          isDisabled={isDisabled}
          selectEnums={selectEnums}
          files={files}
          handleFileDelete={handleFileDelete}
          handleFileUpload={handleFileUpload}
          fields={fieldGroup2B}
        />
      ),
      icon: <SchoolIcon />,
    },
    {
      pageLabel: "Sociální",
      page: (
        <FieldFactory
          isDisabled={isDisabled}
          selectEnums={selectEnums}
          filter={fieldFilter}
          fields={fieldGroup3}
        />
      ),
      icon: <FlareIcon />,
    },
    {
      pageLabel: "Práce",
      page: (
        <FieldFactory
          isDisabled={isDisabled}
          selectEnums={selectEnums}
          filter={fieldFilter}
          handleFileDelete={handleFileDelete}
          handleFileUpload={handleFileUpload}
          files={files}
          fields={FIELD_GROUP_4}
        />
      ),
      icon: <BusinessCenterIcon />,
    },
    {
      pageLabel: "Doklady",
      page: (
        <FieldFactory
          handleFileDelete={handleFileDelete}
          handleFileUpload={handleFileUpload}
          files={files}
          isDisabled={isDisabled}
          fields={FIELD_GROUP_5}
        />
      ),
      icon: <DescriptionIcon />,
    },
    {
      pageLabel: "Doklady",
      page: (
        <FieldFactory
          handleFileDelete={handleFileDelete}
          handleFileUpload={handleFileUpload}
          files={files}
          isDisabled={isDisabled}
          fields={FIELD_GROUP_5_FOREIGNER}
        />
      ),
      icon: <DescriptionIcon />,
    },
    {
      pageLabel: "Práce",
      page: (
        <FieldFactory
          isDisabled={isDisabled}
          selectEnums={selectEnums}
          filter={fieldFilter}
          handleFileDelete={handleFileDelete}
          handleFileUpload={handleFileUpload}
          files={files}
          fields={FIELD_GROUP_4_ANNOTATOR}
        />
      ),
      icon: <BusinessCenterIcon />,
    },
  ];

  const pagesNormal = [0, 1, 2, 3, 4, 5];

  const pagesPartTimer = [0, 1, 2, 3, 7, 5];

  const pagesNormalForeigner = [0, 1, 2, 3, 4, 6];

  const pagesNormalPartTimerForeigner = [0, 1, 2, 3, 7, 6];

  const pagesAnnotator = [0, 1, 3, 7, 5];

  const pagesAnnotatorForeigner = [0, 1, 3, 7, 6];

  let defaultPages: any = [];

  let chosenPages: any = [];

  if (qst.isForeigner) {
    switch (qst.typeOfCandidate) {
      case 1:
        chosenPages = pagesNormalForeigner;
        break;
      case 2:
        chosenPages = pagesNormalPartTimerForeigner;
        break;
      case 3:
        chosenPages = pagesAnnotatorForeigner;
        break;
    }
  } else {
    switch (qst.typeOfCandidate) {
      case 1:
        chosenPages = pagesNormal;
        break;
      case 2:
        chosenPages = pagesPartTimer;
        break;
      case 3:
        chosenPages = pagesAnnotator;
        break;
    }
  }

  for (const pageIndex of chosenPages) {
    defaultPages.push(pagesDatabase[pageIndex]);
  }

  const recapPage = {
    pageLabel: "Shrnutí",
    page: (
      <RecapPage
        files={files}
        defaultPages={defaultPages}
        isDisabled={isDisabled}
        fieldFilter={fieldFilter}
        pages={pageDict}
        selectEnums={selectEnums}
        setPage={setPageIndex}
      />
    ),
    icon: <CheckCircleIcon />,
  };

  const incrementBadge = (key: any) => {
    if (!badgeCount[key]) {
      badgeCount[key] = 1;
    } else {
      badgeCount[key] = badgeCount[key] + 1;
    }
  };

  //adding last recap page always at the en
  defaultPages.push(recapPage);

  //check for warning badges
  const errorKeys = Object.keys(errors);
  const touchedKeys = Object.keys(touched);
  let visitedKeys: string[] = [];

  //TODO: refactor this loop also used in recap page
  Object.keys(pageDict).forEach((key) => {
    let items = pageDict[key];
    items.forEach((item: any) => {
      //if finds an error on a page increment the badge count
      if (errorKeys.includes(item.name) && touchedKeys.includes(item.name)) {
        incrementBadge(key);
      }

      //TODO: problem with doubling employee file
      if (Object.keys(item).includes("componentToShow")) {
        item.componentToShow.forEach((subItem: any) => {
          if (
            errorKeys.includes(subItem.name) &&
            touchedKeys.includes(subItem.name) &&
            !visitedKeys.includes(subItem.name)
          ) {
            incrementBadge(key);
            visitedKeys.push(subItem.name);
          }
        });
      }

      if (Object.keys(item).includes("componentRelated")) {
        item.componentRelated.forEach((subItem: any) => {
          if (
            errorKeys.includes(subItem.name) &&
            touchedKeys.includes(subItem.name) &&
            !visitedKeys.includes(subItem.name)
          ) {
            incrementBadge(key);
            visitedKeys.push(subItem.name);
          }
        });
      }
    });
  });

  //add badge to tabs that have error
  Object.keys(badgeCount).forEach((key: any) => {
    defaultPages[key].icon = (
      <Badge badgeContent={badgeCount[key]} color="secondary">
        {defaultPages[key].icon}
      </Badge>
    );
  });

  return defaultPages;
};

export default PageConfig;
