import { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import LoadingCircle from "../../../../components/LoadingCircle";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Grid2 as Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import ErrorLayout from "../../../../components/layout/ErrorLayout";
import { DateTime } from "luxon";
import { GET_POSTS, SEND_POST } from "./gql";

const Chat = () => {
  const { id } = useParams<{ id: string }>();

  const theme = useTheme();

  const upSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const { loading, data, error } = useQuery(GET_POSTS, {
    variables: {
      questionnaireId: id ? parseInt(id) : "",
    },
  });

  const [sendPost, { error: updateError }] = useMutation(SEND_POST, {
    update(cache, sendPost) {
      let newQuestionnaire = JSON.parse(JSON.stringify(data.questionnaire));

      let newPosts = data.questionnaire.chat.posts.concat([
        sendPost.data.postCreate,
      ]);

      newQuestionnaire.chat.posts = newPosts;

      cache.writeQuery({
        query: GET_POSTS,
        variables: {
          questionnaireId: id ? parseInt(id) : "",
        },
        data: { questionnaire: newQuestionnaire },
      });
    },
  });

  const [postText, setPostText] = useState("");
  const [isSendDisable, setSendDisable] = useState(true);

  const onChange = (e: any) => {
    setPostText(e.target.value);

    if (e.target.value.length > 0) {
      setSendDisable(false);
    } else {
      setSendDisable(true);
    }
  };

  const handleSubmit = () => {
    if (postText.length > 0) {
      const newPost = sendPost({
        variables: {
          text: postText,
          chatId: parseInt(data.questionnaire.chat.id),
        },
      });
      newPost
        .then((succ) => {
          setPostText("");
          setSendDisable(true);
        })
        .catch((err) => {});
    }
  };

  if (loading || data === undefined) return <LoadingCircle />;

  if (error) return <ErrorLayout errorMsg={error.message} />;
  if (updateError) return <ErrorLayout errorMsg={updateError.message} />;

  return (
    <>
      <Grid>
        <Typography variant="h6">Komentáře</Typography>
        <Typography>
          Zde budeš dostávat zprávy od HR, nebo můžeš napsat dotaz ohledně tvého
          nástupu.
        </Typography>
      </Grid>
      <Grid>
        <Box mt={2}>
          <TextField
            placeholder="Sem napiš co máš na srdci"
            value={postText}
            fullWidth
            variant="outlined"
            onChange={onChange}
          />
        </Box>
      </Grid>
      <Grid>
        <Box mt={1}>
          <Button
            disableElevation
            disabled={isSendDisable}
            fullWidth={true}
            variant="contained"
            onClick={handleSubmit}
          >
            Odeslat
          </Button>
        </Box>
      </Grid>
      <Grid>
        <Box
          sx={{
            overflowY: "scroll",
            maxHeight: upSmallScreen ? "600px" : "400px",
          }}
        >
          {data.questionnaire.chat?.posts
            ?.slice()
            .reverse()
            .map((item: any, index: number) => {
              return (
                <Box
                  mt={2}
                  key={index}
                  sx={{
                    overflowY: "scroll",
                    maxHeight: upSmallScreen ? "600px" : "400px",
                  }}
                >
                  <Grid>
                    <Typography color={grey[500]} variant="caption">
                      {item.createdBy.username},{" "}
                      {DateTime.fromISO(item.createdAt).toFormat(
                        "d.M.yyyy H:mm"
                      )}
                    </Typography>
                  </Grid>
                  {item.text}
                </Box>
              );
            })}
        </Box>
      </Grid>
    </>
  );
};

export default Chat;
