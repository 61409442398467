import { GET_LEAVING_QUESTIONNAIRE } from "../gql";
import parse from "html-react-parser";
import openImage from "../../../../image/leaving.png";
import LoadingCircle from "../../../../components/LoadingCircle";
import QuestionnaireStateMessage from "../../../../components/QuestionnaireStateMessage";
import { isQuestionnaireDisabled } from "../../../../utils/QuestionnaireUtils";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  useTheme,
  Grid2 as Grid,
  Typography,
} from "@mui/material";
import ErrorLayout from "../../../../components/layout/ErrorLayout";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";

const LeavingWelcome = () => {
  const { loading, data, error } = useQuery(GET_LEAVING_QUESTIONNAIRE);

  const navigate = useNavigate();
  const theme = useTheme();

  if (error) return <ErrorLayout errorMsg={error.message} />;
  if (loading || data === undefined) return <LoadingCircle />;

  return (
    <>
      <Container maxWidth="md">
        <Grid container spacing={3}>
          <Grid size={{ xs: 12 }}>
            <Box mb={2}>
              <Typography variant="h2">
                <AssignmentReturnIcon
                  sx={{ fontSize: "1em", position: "relative", top: "8px" }}
                  // fontSize="large"
                  color="primary"
                />
                Dotazník výstupní
              </Typography>
            </Box>
            <Card
              sx={{
                "&:last-child": {
                  [theme.breakpoints.up("sm")]: {
                    paddingBottom: theme.spacing(3),
                  },
                },
              }}
            >
              <CardMedia
                sx={{
                  height: 170,
                  [theme.breakpoints.up("sm")]: {
                    height: 250,
                  },
                }}
                component="img"
                image={openImage}
                title="Metro image"
              />

              <CardContent
                sx={{
                  padding: theme.spacing(3),

                  [theme.breakpoints.up("sm")]: {
                    padding: theme.spacing(6),
                  },
                }}
              >
                <QuestionnaireStateMessage
                  state={data.leavingQuestionnaire.state}
                />

                <Box mb={5}>
                  <Button
                    disableElevation
                    variant="contained"
                    size="large"
                    fullWidth
                    color="primary"
                    onClick={() => {
                      navigate("/dotaznikvystup/1/formular");
                    }}
                  >
                    {isQuestionnaireDisabled(data.leavingQuestionnaire.state)
                      ? "Zobrazit dotazník"
                      : "Vyplnit dotazník"}
                  </Button>
                </Box>

                <Box
                  sx={{
                    "&  body": {
                      fontSize: "16px !important",
                    },
                    "&  font": {
                      fontSize: "16px !important",
                    },
                    "&  h2": {
                      fontSize: "20px",
                    },
                    "&  a": {
                      zIndex: 200,
                    },
                  }}
                >
                  {data.leavingQuestionnaire.customText &&
                    parse(data.leavingQuestionnaire.customText)}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default LeavingWelcome;
