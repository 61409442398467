import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import useUserStore from "../store/userStore";
import { useEffect } from "react";
import LoadingMetro from "../components/LoadingMetro";
import useConsentStore from "../store/consentStore";
import ErrorLayout from "../components/layout/ErrorLayout";

const GET_ME = gql`
  query {
    me {
      id
      email
      username
      locale
      authorized
      roles {
        name
      }
      mainEmployment {
        id
      }
    }
  }
`;

const GET_CONSENT_ONLINE = gql`
  query {
    consentOnline {
      ico
      email
      phone
      createdAt
      deletedAt
      createdById
      text {
        id
        text
        type
        validTo
      }
    }
  }
`;

const GET_CONSENT_OWN_HANDS = gql`
  query {
    consentOwnHands {
      id
      type
      createdAt
      text {
        id
        text
        type
        validTo
      }
    }
  }
`;

export const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { loading, data } = useQuery(GET_ME);
  const location = useLocation();
  const {
    setUser,
  } = useUserStore();

  useEffect(() => {
    if (data?.me) {
      setUser(data.me);
    }
  }, [data, setUser]);

  if (loading) {
    return <LoadingMetro />;
  }

  if (!loading && !data.me) {
    return (
      <Navigate
        to={`/login/external?redirectTo=${encodeURIComponent(location.pathname)}`}
        replace
      />
    );
  }

  return children;
};

export const ConsentGuard = ({ children }: { children: React.ReactNode }) => {
  const { data, loading } = useQuery(GET_ME);
  const {
    data: consentData,
    loading: consentLoading,
  } = useQuery(GET_CONSENT_ONLINE);
  const {
    data: consentOwnHandsData,
    loading: consentOwnHandsLoading,
  } = useQuery(GET_CONSENT_OWN_HANDS);

  const {
    setUser,
    setIco,
  } = useUserStore();

  const {
    consentType,
    setConsentType,
    setEmail,
    setPhone,
    setConsentOnlineText,
    setConsentOnlineCreatedById,
    setConsentOnlineCreatedAt,
    setConsentDialogOpen,
    setConsentOnlineDeletedAt,
    setConsentOwnHandsCreatedAt,
    setConsentOwnHandsText,
  } = useConsentStore();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (data?.me) {
      setUser(data.me);
    }

    if (consentOwnHandsData?.consentOwnHands?.type) {
      setConsentType(consentOwnHandsData?.consentOwnHands?.type);
      setConsentOwnHandsText(consentOwnHandsData?.consentOwnHands?.text);
      setConsentOwnHandsCreatedAt(consentOwnHandsData?.consentOwnHands?.createdAt);
    } else {
      if (
        !loading &&
        !consentOwnHandsLoading &&
        data?.me
      ) {
        if (!consentData?.consentOnline?.ico) {
          return;
        }
        setConsentDialogOpen(true);
        navigate("/consent");
      }
    }
  }, [consentType, data, loading, navigate, setUser, location.pathname, consentOwnHandsData, setConsentType, consentOwnHandsLoading, setConsentDialogOpen, consentData, setConsentOwnHandsCreatedAt, setConsentOwnHandsText]);

  useEffect(() => {
    if (consentData?.consentOnline) {
      setEmail(consentData?.consentOnline?.email || '');
      setPhone(consentData?.consentOnline?.phone || '');
      setIco(consentData?.consentOnline?.ico || 0);
      setConsentOnlineCreatedAt(consentData?.consentOnline?.createdAt);
      setConsentOnlineCreatedById(consentData?.consentOnline?.createdById);
      setConsentOnlineDeletedAt(consentData?.consentOnline?.deletedAt);
      setConsentOnlineText(consentData?.consentOnline?.text);
    }
  }, [consentData, consentLoading, setConsentOnlineCreatedAt, setConsentOnlineCreatedById, setConsentOnlineDeletedAt, setConsentOnlineText, setEmail, setIco, setPhone]);

  return children;
};

export const AuthRedirect = () => {
  const { data, loading, error } = useQuery(GET_ME);
  const {
    setUser,
  } = useUserStore();

  useEffect(() => {
    if (data?.me) {
      setUser(data.me);
    }
  }, [data, setUser]);

  if (loading) {
    return <LoadingMetro />;
  }

  if (error) return <ErrorLayout errorMsg={error.message || ""} />;

  if (!loading && data.me) return <Navigate to="/" replace />;

  return null;
};
