import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Typography,
  Box,
  Container,
  Card,
  CardMedia,
  CardContent,
  Alert,
  useTheme,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import { TextField } from "formik-material-ui";
import { Formik, Field, Form } from "formik";
import UpdateSnackBar from "../../components/UpdateSnackBar";
import registerHero from "../../image/register-hero.png";
import PasswordChecker from "./PasswordChecker";
import {
  getCapitalValidation,
  getLengthValidation,
  getSmallCaseValidation,
  getNumberValidation,
} from "./PasswordChecker";
import PasswordField from "./PasswordField";

import { LOGIN_EXTERNAL_USER } from "./gql";
import { REGISTER_EXTERNAL_USER } from "./gql";
import { RegisterFormValuesType } from "../../utils/Types/RegisterFormValuesType";
import { SnackBarType } from "../../utils/Types/SnackBarType";
import { RegisterFormErrorsType } from "../../utils/Types/RegisterFormErrorsType";
import CopyrightFooter from "../../components/CopyrightFooter";

const ExternalRegister = () => {
  let navigate = useNavigate();

  const theme = useTheme();

  let { token, email } = useParams();

  const [submit, { data }] = useMutation(REGISTER_EXTERNAL_USER);
  const [login, { data: loginData }] = useMutation(LOGIN_EXTERNAL_USER);

  const [snackBar, setSnackBar] = useState<SnackBarType>({
    open: false,
    message: "",
    severity: "info",
  });
  const [isShowError, setShowError] = useState<boolean>(false);

  const [credentials, setCredentials] = useState<RegisterFormValuesType>({
    email: "",
    password: "",
    passwordRepeat: "",
  });

  //effect called when registration is successful
  useEffect(() => {
    if (data) {
      if (data.registerExternalUser) {
        handleLogin();
      }
    }
  }, [data]);

  //effect called when login was successful
  useEffect(() => {
    if (loginData) {
      if (loginData.logInExternalUser.user) {
        navigate("/");
        window.location.reload();
      }
    }
  }, [loginData]);

  const handleSnackBarClose = () => {
    setSnackBar({ open: false, message: "", severity: "info" });
  };

  const handleLogin = () => {
    login({
      variables: {
        input: {
          email: credentials?.email,
          password: credentials?.password,
        },
      },
    }).catch((err) => {
      console.log(err);
      //setShowError(true)
      //setSubmitting(false);
    });
  };

  const handleFormSubmit = (
    vals: RegisterFormValuesType,
    { setSubmitting }: any
  ) => {
    submit({
      variables: {
        input: {
          email: vals.email,
          password: vals.password,
          token: token,
        },
      },
    }).catch((err) => {
      console.log(err.message);
      setShowError(true);
      setSubmitting(false);
    });

    setCredentials({
      email: vals.email,
      password: vals.password,
      passwordRepeat: vals.passwordRepeat,
    });
    setSubmitting(false);
  };

  const validation = (values: RegisterFormValuesType) => {
    const errors: RegisterFormErrorsType = {};
    const requiredMsg = "Pole musí být vyplněno";

    if (!values.email) {
      errors.email = requiredMsg;
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Neplatný email";
    }

    if (!values.password) {
      errors.password = requiredMsg;
    }

    if (!values.passwordRepeat) {
      errors.passwordRepeat = requiredMsg;
    } else if (values.passwordRepeat !== values.password) {
      errors.passwordRepeat = "Hesla se neshodují";
    }

    if (
      !getCapitalValidation(values.password) ||
      !getLengthValidation(values.password) ||
      !getSmallCaseValidation(values.password) ||
      !getNumberValidation(values.password)
    ) {
      errors.password = "Zadané heslo nesplňuje podmínky bezpečnosti";
    }

    return errors;
  };

  const initialFormValues: RegisterFormValuesType = {
    email: email || "",
    password: "",
    passwordRepeat: "",
  };

  return (
    <>
      <UpdateSnackBar
        message={snackBar.message}
        onClose={handleSnackBarClose}
        isOpen={snackBar.open}
        severity={snackBar.severity}
      />

      <Container maxWidth="md">
        <Card
          sx={{
            marginTop: theme.spacing(3),
            [theme.breakpoints.up("sm")]: {
              display: "flex",
            },
          }}
        >
          <CardMedia
            sx={{
              minHeight: 105,
              minWidth: 105,

              [theme.breakpoints.up("sm")]: {
                width: 450,
                minHeight: 450,
              },
            }}
            component="img"
            image={registerHero}
          />
          <CardContent
            sx={{
              padding: theme.spacing(3),

              [theme.breakpoints.up("sm")]: {
                padding: theme.spacing(6),
              },
            }}
          >
            <Box mb={1}>
              <Typography variant="h4">Ahoj seznamáku!</Typography>
            </Box>
            <Box mb={1}>
              <Typography>
                Nejdříve se zaregistruj vyplněním <b>libovolného </b>hesla.
              </Typography>
            </Box>
            {isShowError && (
              <Box mb={2}>
                <Alert severity="error">
                  Registrace se nezdařila, kontaktuj{" "}
                  <a href="mailto:personalni@firma.seznam.cz?subject=Metro - neúspěšná registrace">
                    personalni@firma.seznam.cz
                  </a>
                </Alert>
              </Box>
            )}

            <Formik
              initialValues={initialFormValues}
              validate={validation}
              onSubmit={handleFormSubmit}
            >
              {({ isValid }) => (
                <Form>
                  <label htmlFor="email">Login:</label>
                  <Field
                    component={TextField}
                    variant="outlined"
                    size="small"
                    margin="dense"
                    fullWidth
                    name="email"
                    disabled={true}
                  />

                  <Box mt={2}>
                    <PasswordField name={"password"} label="Nové heslo:" />
                    <PasswordChecker />
                  </Box>

                  <PasswordField
                    name={"passwordRepeat"}
                    label="Nové heslo znovu:"
                  />

                  <Box mt={1}>
                    <Button
                      disableElevation
                      variant="contained"
                      color="primary"
                      fullWidth
                      type="submit"
                      disabled={!isValid}
                    >
                      Registrovat se
                    </Button>
                  </Box>
                </Form>
              )}
              {/* end of formik function */}
            </Formik>

            <Box mt={2}>
              Máš už založený účet?{" "}
              <Link to="/login/external">Přihlásit se</Link>
            </Box>
          </CardContent>
        </Card>
        <CopyrightFooter />
      </Container>
    </>
  );
};

export default ExternalRegister;
