import { useQuery } from "@apollo/client";
import { GET_QUESTIONNAIRES } from "./gql";
import { useEffect } from "react";
import { Container, Typography } from "@mui/material";
import QuestionnaireEntry from "./QuestionnaireEntry";
import { QuestionnaireType } from "../../../utils/Types/QuestionnaireType";
import Grid from "@mui/material/Grid2";
import LoadingCircle from "../../../components/LoadingCircle";
import BasicLayout from "../../../components/layout/BasicLayout";
import ErrorLayout from "../../../components/layout/ErrorLayout";
import { DateTime } from "luxon";
import { stat } from "fs/promises";

const QuestionnairesOverview = () => {
  const {
    data: questionnairesData,
    loading: questionnairesLoading,
    error: questionnaireError,
  } = useQuery(GET_QUESTIONNAIRES);

  useEffect(() => {
    if (questionnairesData) {
      console.log("QST OVERVIEW");
      console.log(questionnairesData);
    }
  }, [questionnairesData]);

  const sortQuestionnairesByStartDate = (
    a: QuestionnaireType,
    b: QuestionnaireType
  ) => {
    console.log(a);
    const startDateA = DateTime.fromISO(a.startDate);
    const startDateB = DateTime.fromISO(b.startDate);

    if (startDateA < startDateB) {
      return -1;
    } else if (startDateA > startDateB) {
      return 1;
    }
    return 0;
  };

  if (questionnairesLoading || !questionnairesData) return <LoadingCircle />;
  if (questionnaireError)
    return <ErrorLayout errorMsg={questionnaireError.message || ""} />;

  return (
    <BasicLayout>
      <Container maxWidth="sm">
        <Grid container spacing={2}>
          <Grid>
            <Typography variant="h2">Nástupní dotazníky</Typography>
          </Grid>
          {questionnairesData.questionnaires?.length === 0 && (
            <Grid size={{ xs: 12 }}>
              <Typography variant="body1">
                Nemáte žádné aktivní dotazníky.
              </Typography>
            </Grid>
          )}
          {[...questionnairesData.questionnaires]
            .sort(sortQuestionnairesByStartDate)
            .map((qstItem: QuestionnaireType) => {
              return (
                <Grid key={qstItem.id} size={{ xs: 12 }}>
                  <QuestionnaireEntry questionnaire={qstItem} />
                </Grid>
              );
            })}
        </Grid>
      </Container>
    </BasicLayout>
  );
};

export default QuestionnairesOverview;
