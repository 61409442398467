import FieldFactory from "../../Questionnaire/FieldComponents/FieldFactoryLeaving";

interface QuestionnaireGeneratorType {
  isDisabled: boolean;
  //TODO: FIX THIS TYPE
  config: any;
}

const QuestionnaireGenerator = ({
  config,
  isDisabled,
}: QuestionnaireGeneratorType) => {
  const inputList = config ? JSON.parse(config) : [];

  return (
    <>
      <FieldFactory fields={inputList} isDisabled={isDisabled} />
    </>
  );
};

export default QuestionnaireGenerator;
