import parse from "html-react-parser";
import {
  MenuItem,
  FormControl,
  Grid,
  Typography,
  FormHelperText,
  Select,
  Box,
} from "@mui/material";
import { Fragment } from "react";
import { useFormikContext } from "formik";
import { TextField as TextFieldMUI } from "@mui/material";

/**

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textarea: {
    fontSize: "16px",
    padding: theme.spacing(2),
    fontFamily: "TriviaSeznam",
    width: "100%",
  },
}));
 */

const FieldFactoryLeaving = (props) => {
  // const classes = useStyles();

  const { values, errors, touched, setFieldTouched, setFieldValue } =
    useFormikContext();

  const {
    isDisabled,
    files,
    handleFileDelete,
    handleFileUpload,
    fields,
    filter,
    selectEnums,
  } = props;

  const styleVariant = "outlined";

  const handleDateChange = (itemName, newDate) => {
    setFieldValue(itemName, newDate);
  };

  const handleDateBlur = (itemName) => {
    setFieldTouched(itemName);
  };

  const handleChangeField = (idx, newValue) => {
    let fieldsNew = fields;
    fieldsNew[idx].val = newValue;
    setFieldValue("questionnaireConfig", JSON.stringify(fieldsNew));
    console.log(idx, newValue);
  };

  return (
    <>
      {fields.map((itm, idx) => {
        if (idx === 0) return;

        let itemLabel = itm.otazka;

        if (itm.povinny === "A") {
          itemLabel = itemLabel + " *";
        }

        if (itm.typodpovedi === "select") {
          let menuItems = JSON.parse(itm.parametrtypu);

          if (selectEnums !== undefined) {
            //adds menuItems fetched from DBS
            if (itm.name in selectEnums) {
              menuItems = selectEnums[itm.name];
            }
          }

          try {
            let x = JSON.parse(itm.val);
            itm.val = x;
          } catch { }

          return (
            <Fragment key={idx}>
              <Typography variant={itm?.variant}>{parse(itemLabel)}</Typography>
              <FormControl
                fullWidth={true}
                variant={styleVariant}
                // className={classes.formControl}
                key={idx}
                error={
                  errors[itm.cisloradky] && touched[itm.cisloradky]
                    ? true
                    : false
                }
              >
                <Select
                  labelId={"select" + itm.cisloradky}
                  value={
                    itm.val?.value === undefined ? itm.val : itm.val?.value
                  }
                  displayEmpty
                  disabled={isDisabled || itm.isDisabled}
                  onChange={(e) =>
                    handleChangeField(
                      itm.cisloradky,
                      JSON.stringify({
                        value: e.target.value,
                        label:
                          menuItems[0].id === undefined
                            ? menuItems.filter(
                              (f) => f.value === e.target.value
                            )[0].label
                            : menuItems.filter(
                              (f) => f.id === e.target.value
                            )[0].text,
                      })
                    )
                  }
                  onBlur={(e) => setFieldTouched(itm.cisloradky, true)}
                  label={itm.label}
                >
                  {menuItems.map((el, idx2) => {
                    if (el.id === undefined)
                      return (
                        <MenuItem key={idx2 * idx + 100} value={el.value}>
                          {el.label}
                        </MenuItem>
                      );
                    if (el.id !== undefined)
                      return (
                        <MenuItem key={idx2 * idx + 100} value={el.id}>
                          {el.text}
                        </MenuItem>
                      );
                  })}
                </Select>

                <FormHelperText>
                  {errors[itm.cisloradky] && touched[itm.cisloradky]
                    ? errors[itm.cisloradky]
                    : " "}
                </FormHelperText>
              </FormControl>
            </Fragment>
          );
        }

        if (itm.typodpovedi === "typography") {
          return (
            <Grid key={idx} item>
              <Box mt={itm.mt} mb={itm.mb}>
                <Typography variant={itm.variant}>{itm.otazka}</Typography>
              </Box>
            </Grid>
          );
        }

        if (itm.typodpovedi === "textarea" || itm.typodpovedi === "text") {
          return (
            <Box mb={2} key={idx}>
              <Typography variant={itm?.variant}>{parse(itemLabel)}</Typography>
              <FormControl
                fullWidth
                variant={styleVariant}
                // className={classes.formControl}
                key={idx}
                error={
                  errors[itm.cisloradky] && touched[itm.cisloradky]
                    ? true
                    : false
                }
              >
                <TextFieldMUI
                  id="filled-multiline-static"
                  // label="Multiline"
                  multiline
                  fullWidth
                  placeholder={itm.parametrtypu}
                  value={itm.val}
                  onChange={(e) =>
                    handleChangeField(itm.cisloradky, e.target.value)
                  }
                  onBlur={() => setFieldTouched(itm.cisloradky, true)}
                  rows={4}
                  // defaultValue="Default Value"
                  variant={styleVariant}
                  disabled={isDisabled}
                  error={
                    errors[itm.cisloradky] && touched[itm.cisloradky]
                      ? true
                      : false
                  }
                // error={true}
                />
                <FormHelperText>
                  {errors[itm.cisloradky] && touched[itm.cisloradky]
                    ? errors[itm.cisloradky]
                    : " "}
                </FormHelperText>
              </FormControl>
            </Box>
          );
        }
        return "";
      })}
    </>
  );
};

export default FieldFactoryLeaving;
