import { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { InputAdornment, IconButton } from "@mui/material";
import { Field } from "formik";
import { TextField } from "formik-material-ui";

interface PasswordFieldType {
  label: string;
  name: string;
}

const PasswordField = ({ label, name }: PasswordFieldType) => {
  const [isShowPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!isShowPassword);
  };

  return (
    <>
      <label htmlFor="password">
        {label}
        <span>*</span>
      </label>
      <Field
        component={TextField}
        type={isShowPassword ? "text" : "password"}
        helperText=" "
        variant="outlined"
        size="small"
        margin="dense"
        fullWidth
        name={name}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleShowPassword}>
                {isShowPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default PasswordField;
