import { AppBar, Toolbar, Box, IconButton, Button, Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { NavLink, useNavigate } from "react-router-dom";
import logoMetro from "../image/logo.png";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { useState } from "react";
import MobileNav from "../pages/Homepage/MobileNav";
import useUserStore from "../store/userStore";
import useConsentStore from "../store/consentStore";


const LOGOUT_USER = gql`
  mutation logout {
    logout
  }
`;

const Navigation = () => {
  const [logout] = useMutation(LOGOUT_USER);

  const {
    user,
    hasLeavingQuestionnaire,
  } = useUserStore();

  const {
    consentOnlineText,
    consentOnlineDeletedAt,
  } = useConsentStore();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleLogout = () => {
    logout({
      onCompleted: () => {
        window.location.reload();
      },
      onError: () => {
        window.location.reload();
      },
    });
  };

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open: boolean) => () => {
    setMobileNavOpen(open);
  };

  return (
    <>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: "#ffffff",
          color: "grey",
          boxShadow:
            "0 1px 3px 0 rgba(0,0,0,.03), 0 2px 32px 0 rgba(0,0,0,.05)",
        }}
      >
        <Toolbar>
          <Box display={{ xs: "block", sm: "block", md: "none" }}>
            <IconButton
              onClick={toggleDrawer(true)}
              edge="start"
              sx={{
                marginRight: 2,
              }}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "&:hover": {
                cursor: "pointer",
              },
              "& img": {
                width: "130px",
              },
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            <img src={logoMetro} alt="logo" />
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              alignItems: "center",
            }}
            textAlign="center"
            display={{ xs: "none", md: "block", lg: "block" }}
          >
            <NavLink to="/">
              <Button size="large" sx={{ margin: 1, color: "black" }}>
                Rozcestník
              </Button>
            </NavLink>
            <NavLink to="/dotazniky">
              <Button size="large" sx={{ margin: 1, color: "black" }}>
                Nástupní dotazníky
              </Button>
            </NavLink>
            {hasLeavingQuestionnaire && (
              <NavLink to="/dotaznikvystup/1/uvod">
                <Button size="large" sx={{ margin: 1, color: "black" }}>
                  Výstupní dotazník
                </Button>
              </NavLink>
            )}

            <NavLink to="/dokumenty">
              <Button size="large" sx={{ margin: 1, color: "black" }}>
                Šanon
              </Button>
            </NavLink>

            {(consentOnlineText || consentOnlineDeletedAt) && (
              <NavLink to="/consent">
                <Button size="large" sx={{ margin: 1, color: "black" }}>
                  Elektronická komunikace
                </Button>
              </NavLink>
            )}
          </Box>

          <Box display={{ xs: "none", md: "block", lg: "block" }}>
            {user?.email}
          </Box>

          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <ExpandMoreIcon />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleLogout}>Odhlásit se</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <MobileNav
        hasLeavingQuestionnaire={hasLeavingQuestionnaire}
        isOpen={isMobileNavOpen}
        close={toggleDrawer(false)}
      />
    </>
  );
}

export default Navigation;
