export const downloadFile = (id: number) => {
  const href = `${process.env.REACT_APP_SERVER_URL}/api/file/${id}`;
  window.open(href);
};

export const translateEntityKind = (
  enumerations: [{ id: string; value: string; text: string }],
  entityKind: string
) => {
  const found = enumerations.find((item) => item.value === entityKind);
  return found ? found.text : entityKind;
};
