import React, { useEffect } from "react";
import { QUESTIONNAIRE_STATE } from "../../utils/QuestionnaireUtils";
import { GET_LEAVING_QUESTIONNAIRE } from "../../pages/Homepage/LeavingQuestionnaire/gql";
import { useQuery } from "@apollo/client";
import useUserStore from "../../store/userStore";
import Navigation from "../Navigation";
import { useLocation } from "react-router-dom";
import { useScrollToTop } from "../../hooks/useScrollToTop";

const AuthorizedLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  useScrollToTop();
  const location = useLocation();

  const {
    data,
  } = useQuery(GET_LEAVING_QUESTIONNAIRE);

  const {
    setHasLeavingQuestionnaire,
  } = useUserStore();

  useEffect(() => {
    if (
      data &&
      data.leavingQuestionnaire &&
      data.leavingQuestionnaire.state !== QUESTIONNAIRE_STATE.CANCELED
    ) {
      setHasLeavingQuestionnaire(true);
    }
  }, [data, setHasLeavingQuestionnaire, location.pathname]);

  return (
    <>
      <Navigation />

      {children}
    </>
  );
};

export default AuthorizedLayout;
