import { getPageDict } from "../../../utils/QuestionnaireUtils";
import { QuestionnaireType } from "../../../utils/Types/QuestionnaireType";
import { CANDIDATE_TYPE } from "./Settings";

export const getInitialFormValues = (qst: any) => {
  if (!qst) return;

  const pageDict: any = getPageDict(qst);

  //TODO: FIX local storage

  /**

    const valuesFromLocalStorage = localStorage.getItem(
      LOCALSTORAGE_QUESTIONNAIRE_KEY
    );
    const storedDate = localStorage.getItem(
      LOCALSTORAGE_QUESTIONNAIRE_KEY_UPDATED
    );
    let storedEmail = localStorage.getItem(
      LOCALSTORAGE_QUESTIONNAIRE_KEY_EMAIL
    );

    const serverDate = new Date(qst.updatedAt);
    //check if there is data in local storage

    let valueSource = qst;

    if (valuesFromLocalStorage && storedDate && storedEmail) {
      const localData = JSON.parse(valuesFromLocalStorage);
      const localDate = new Date(storedDate);
      storedEmail = CryptoAES.decrypt(
        JSON.parse(storedEmail),
        LOCALSTORAGE_PASS
      ).toString(CryptoENC);

      //check if data in meanwhile were updated elsewhere if yes then dont load from localStore
      if (localDate > serverDate) {
        valueSource = localData;
      }
    }

 */

  let valueSource = qst;
  let values: any = {};
  //manually setting field that is not on any page - prohlasuji ze jsem vse po sobe zkontroloval a ze jsou udaje pravdive
  values["agreement"] = false;

  Object.keys(pageDict).forEach((pageNumber: any) => {
    pageDict[pageNumber].forEach((item: any) => {
      const valueFromSource = getDefaultValue(valueSource, item);

      if (item.type === "typography") return;

      if (item.type === "dynamictypography") {
        values[item.name] = qst[item.name as keyof QuestionnaireType];
        return;
      }

      //handles init of nested fields int selectshowmore
      if (item.type === "selectshowmore") {
        values[item.name] = valueFromSource;

        item.componentToShow.forEach((tmp: any) => {
          values[tmp.name] = getDefaultValue(valueSource, tmp);

          //TODO: refactor this
          if (tmp.type === "upload") {
            values[tmp.name] = valueFromSource;

            //check uploads that dont have the checkbox for deliver later
            if (!tmp.deliverLaterCheckbox) return;

            tmp.deliverLaterCheckbox.forEach((foo: any) => {
              values[foo.name] = getDefaultValue(valueSource, foo);
            });
            return;
          }
          //TODO: end refactor
        });
        return;
      }

      if (item.type === "checkboxshowmore") {
        values[item.name] = valueFromSource;

        item.componentToShow.forEach((tmp: any) => {
          if (tmp.type === "typography") return;
          values[tmp.name] = getDefaultValue(valueSource, tmp);
        });
        return;
      }

      if (item.type === "upload") {
        values[item.name] = valueFromSource;

        //check uploads that dont have the checkbox for deliver later
        if (!item.deliverLaterCheckbox) return;

        item.deliverLaterCheckbox.forEach((tmp: any) => {
          values[tmp.name] = getDefaultValue(valueSource, tmp);
        });
        return;
      }

      //Adding the support of componentRelated so it get inited for questionnaire
      if (item.type === "birthCombo") {
        values[item.name] = valueFromSource;

        if (!item.componentRelated) return;

        item.componentRelated.forEach((tmp: any) => {
          values[tmp.name] = getDefaultValue(valueSource, tmp);
        });
        return;
      }

      values[item.name] = valueFromSource;
    });
  });

  //adding foreignerDocuments field only if user is foreigner
  if (qst.isForeigner) {
    values.foreignerDocuments = qst.foreignerDocuments;
  }

  values.employeeFile = qst.employeeFile;

  if (
    qst.typeOfCandidate === CANDIDATE_TYPE.parttimer ||
    qst.typeOfCandidate === CANDIDATE_TYPE.annotator
  ) {
    values.healthInsurancePayerDocuments = qst.healthInsurancePayerDocuments;
  }

  if (
    qst.typeOfCandidate === CANDIDATE_TYPE.normal ||
    qst.typeOfCandidate === CANDIDATE_TYPE.parttimer
  ) {
    values.educationDocuments = qst.educationDocuments;
  }

  values.otherDocuments = qst.otherDocuments;

  return values;
};

//TODO: possible to optimize calling this and probably the initi method
//return either defaultValue from itemObject if the value in valueSource is null and the defaultValue is set in itemObject
const getDefaultValue = (valueSource: any, itemObject: any) => {
  let defaultValue = valueSource[itemObject.name];

  if (defaultValue === null && "defaultValue" in itemObject) {
    return itemObject.defaultValue;
  }

  return defaultValue;
};
