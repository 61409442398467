import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Skeleton, Tooltip, Typography, Grid2 as Grid } from "@mui/material";
import BasicLayout from "../../components/layout/BasicLayout";
import ConsentForm from "../../components/consent/ConsentForm";
import useConsentStore, { ConsentTextType, ConsentType } from "../../store/consentStore";
import useUpdateConsentOwnHands from "../../hooks/useUpdateConsentOwnHands";
import ConsentOnlineCancel from "../../components/consent/ConsentOnlineCancel";
import HighlightBlock from "../../components/ui/HighlightBlock/HighlightBlock";
import ConsentState from "../../components/ui/ConsentState/ConsentState";
import { DateTime } from "luxon";

const GET_CONSENT_TEXT = gql`
  query GetConsentText($ico: String) {
    consentText(ico: $ico) {
      id
      text
      type
      ico
      validTo
    }
  }
`;

const ConsentPage = () => {
  const {
    email,
    phone,
    consentType,
    consentOnlineText,
    consentDialogOpen,
    setConsentDialogOpen,
    consentOwnHandsText,
    consentOnlineCreatedById,
    consentOnlineDeletedAt,
    consentOnlineCreatedAt,
    consentOwnHandsCreatedAt,
  } = useConsentStore();

  const { updateConsent } = useUpdateConsentOwnHands();

  const { data, loading } = useQuery(GET_CONSENT_TEXT);

  const handleCloseWarningDialog = ({ reason }: {
    reason?: string;
  }) => {
    if (reason === 'backdropClick') {
      return;
    }
    setConsentDialogOpen(false);
  };

  const handleGiveConsent = () => {
    const consentText = data.consentText.find((consent: any) => consent.type === ConsentTextType.OWN_HANDS);

    if (!consentText) {
      return;
    }

    updateConsent(consentText.id, ConsentType.AGREE);
  };

  const handleConsentLater = () => {
    updateConsent(consentOwnHandsText?.id || 0, ConsentType.LATER);
  };

  if (!data?.consentText?.length && !loading) {
    return (
      <>
        <BasicLayout>
          <Container>
            <Typography variant="h2" mb={2}>
              Elektronická komunikace
            </Typography>

            <Paper
              sx={{
                p: 3,
              }}
            >
              Nemáte žádná data k zobrazení, obraťte se na správce.
            </Paper>
          </Container>
        </BasicLayout>
      </>
    );
  }

  return (
    <>
      <Dialog
        disableEscapeKeyDown
        open={consentDialogOpen}
        onClose={(event, reason) => handleCloseWarningDialog({ reason })}
      >
        <DialogTitle>
          Vyjádření k elektronické komunikaci
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {
              phone && email && consentOnlineCreatedById !== 1
                ? (
                  "Děkujeme za kontaktní údaje pro elektronickou komunikaci. Pokračuj prosím informacemi k doručování dalšího typu písemností."
                )
                : (
                  "Ahoj, abychom spolu mohli v HR záležitostech komunikovat digitálně a využít všechny výhody bezpapírového světa, potřebujeme tvoje vyjádření k elektronické komunikaci, tj. uzavírání a zasílání pracovně právních dokumentů. Prosím, vše si důkladně pročti a vyjádři své rozhodnutí. Děkujeme."
                )
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseWarningDialog({})}>
            Rozumím
          </Button>
        </DialogActions>
      </Dialog>

      <BasicLayout>
        <Container>
          <Typography variant="h2" mb={2}>
            Elektronická komunikace
          </Typography>

          <Paper
            sx={{
              p: 3,
            }}
          >
            {
              loading
                ? Array.from({ length: 10 }).map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="text"
                    width="100%"
                    height={30}
                  />
                ))
                :
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.consentText.find((consent: any) => consent.type === ConsentTextType.HEADER)?.text
                    }}
                  />

                  <HighlightBlock
                    style={{
                      marginTop: 50,
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: consentOnlineText?.text
                          || data?.consentText.find((consent: any) => consent.type === ConsentTextType.ONLINE)?.text
                          || ''
                      }}
                    />

                    <div style={{
                      marginTop: 50,
                      marginBottom: 50,
                    }}>
                      <Box>
                        <ConsentForm />
                      </Box>
                    </div>

                    <ConsentState
                      withdrawn={consentOnlineDeletedAt !== null}
                      style={{
                        position: "absolute",
                        left: "50%",
                        bottom: 0,
                        transform: "translateX(-50%) translateY(50%)",
                      }}
                    >
                      {
                        consentOnlineDeletedAt
                          ? `Souhlas byl zrušen ⋅ ${DateTime.fromISO(consentOnlineDeletedAt.toString()).toFormat(
                            "d.M.yyyy HH:mm"
                          )}`
                          : !consentOnlineCreatedById
                            ? `Údaje uloženy ⋅ ${consentOnlineCreatedAt ? DateTime.fromISO(consentOnlineCreatedAt.toString()).toFormat(
                              "d.M.yyyy HH:mm"
                            ) : ''}`
                            : "Musíte uložit a potvrdit údaje"
                      }
                    </ConsentState>
                  </HighlightBlock>
                </>
            }

            {
              loading
                ? Array.from({ length: 10 }).map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="text"
                    width="100%"
                    height={30}
                  />
                ))
                :
                <HighlightBlock>
                  <div
                    style={{
                      opacity: (!email || !phone || consentOnlineCreatedById) ? 0.4 : 1,
                    }}
                    dangerouslySetInnerHTML={{
                      __html:
                        (consentType !== ConsentType.WITHDRAWAL && consentOwnHandsText?.id !== 0)
                          ? (consentOwnHandsText?.text || data?.consentText.find((consent: any) => consent.type === ConsentTextType.OWN_HANDS)?.text)
                          : data?.consentText.find((consent: any) => consent.type === ConsentTextType.OWN_HANDS)?.text
                    }}
                  />

                  <Box
                    textAlign="center"
                    mb={8}
                  >
                    {
                      ((!email || !phone || consentOnlineCreatedById)
                        || consentType === ConsentType.AGREE)
                        ?
                        (
                          <Grid
                            container
                            mx="auto"
                            mb={2}
                            rowGap={2}
                          >
                            {email && phone && !consentOnlineCreatedById ? (
                              <Grid
                                size={{ xs: 12 }}
                                textAlign="center"
                                alignContent="center"
                                px={{
                                  xs: 0,
                                  md: 1,
                                }}
                              >
                                <ConsentOnlineCancel />
                              </Grid>
                            ) : (
                              <Grid
                                size={{
                                  xs: 12,
                                }}
                                textAlign={{
                                  xs: "center",
                                }}
                              >
                                <Tooltip
                                  arrow
                                  placement="top"
                                  title={
                                    (!email || !phone || consentOnlineCreatedById)
                                      ? "Nemáte potvrzené údaje pro elektronické doručování písemností"
                                      : "Už jste udělil souhlas"
                                  }
                                >
                                  <span>
                                    <Button
                                      disabled
                                      variant="contained"
                                      color="success"
                                      disableElevation
                                    >
                                      Souhlasím s elektronickým doručováním písemností
                                    </Button>
                                  </span>
                                </Tooltip>
                              </Grid>
                            )}
                          </Grid>
                        ) : (
                          <Button
                            onClick={handleGiveConsent}
                            variant="contained"
                            color="success"
                            disableElevation
                          >
                            Souhlasím s elektronickým doručováním písemností
                          </Button>
                        )
                    }
                  </Box>

                  <ConsentState
                    withdrawn={consentType === ConsentType.WITHDRAWAL}
                    style={{
                      position: "absolute",
                      left: "50%",
                      bottom: 0,
                      transform: "translateX(-50%) translateY(50%)",
                    }}
                  >
                    {
                      consentType === ConsentType.AGREE
                        ? `Souhlas byl udělen ⋅ ${DateTime.fromISO(consentOwnHandsCreatedAt?.toString() || '').toFormat(
                          "d.M.yyyy HH:mm"
                        )}`
                        : consentType === ConsentType.WITHDRAWAL
                          ? `Souhlas byl zrušen ⋅ ${DateTime.fromISO(consentOwnHandsCreatedAt?.toString() || '').toFormat(
                            "d.M.yyyy HH:mm"
                          )}`
                          : consentType === ConsentType.LATER
                            ? `Souhlas byl odložen ⋅ ${DateTime.fromISO(consentOwnHandsCreatedAt?.toString() || '').toFormat(
                              "d.M.yyyy HH:mm"
                            )}`
                            : !consentOnlineCreatedById
                              ? "Souhlas zatím nebyl udělen"
                              : "Nemáte potvrzené údaje pro elektronické doručování písemností"
                    }
                  </ConsentState>
                </HighlightBlock>
            }

            <div
              style={{
                marginBottom: 10,
              }}
            >
              Odesláním údajů se zavazujete neprodleně oznámit případnou změnu e-mailové adresy nebo telefonního čísla. Berete na vědomí, že tímto nejsou dotčeny nebo omezeny jiné možnosti a způsoby doručování v souladu s právními předpisy.
            </div>
            <div
              style={{
                marginBottom: 10,
              }}
            >
              Pokud budete chtít elektronicky doručit jakoukoliv pracovněprávní písemnost vy nám, můžete k tomu využít e-mailovou adresu <a href="mailto:personalni@firma.seznam.cz">personalni@firma.seznam.cz</a>. Takovou písemnost musíte podepsat a my vám následně potvrdíme její doručení. Doručení písemnosti nebude účinné, pokud se vám zpráva zaslaná na naši e-mailovou adresu vrátila jako nedoručitelná.
            </div>

            {(!consentType || (consentType !== ConsentType.AGREE && consentType !== ConsentType.LATER && consentType !== ConsentType.WITHDRAWAL))
              && (
                <Box textAlign="center">
                  <Button
                    onClick={handleConsentLater}
                    variant="contained"
                    color="success"
                    disableElevation
                  >
                    Rozmyslím se později
                  </Button>
                </Box>
              )}
          </Paper>
        </Container>
      </BasicLayout>
    </>
  );
};

export default ConsentPage;
