import { gql } from "@apollo/client";

export const GET_LEAVING_QUESTIONNAIRE = gql`
  query {
    leavingQuestionnaire {
      state
      completedAt
      updatedAt
      questionnaireConfig
      customText
      answer1
      answer2
      answer3
      answer4
      answer5
      answer6
      answer7
      answer8
      answer9
      answer10
      answer11
      answer12
      answer13
      answer14
      answer15
      answer16
      answer17
      answer18
      answer19
      answer20
    }
  }
`;

export const GET_QUESTIONNAIRE_REQUIRED = gql`
  query {
    questionnaire {
      firstName
      lastName
      birthLastName
      phone
      birthNumber
      birthDate
      birthPlace
      state
      nationality
      maritalStatus
      isForeigner
      isBankAccountShow
      bankAccountNumber
      permanentAddressCity
      permanentAddressStreet
      permanentAddressPostnumber
      isContactAddressDifferent
      contactAddressCity
      contactAddressStreet
      contactAddressPostnumber
      children
      insuranceNumber
      insuranceCompany
      isHandicap
      isOtherEmployment
      idCardNumber
      idCardValidDate
      employmentOrigin
      isSeizure
      seizureNumber
      isTradeLicense
      tradingLicenseNumber
      isDeliverFileLater
      isCriminalRecord
      educationLevel
      educationCode
      isEducationDeliverFileLater
      isNoInsuranceCompany
      typeOfCandidate
      otherHealthInsurancePayer
      companyIdentificationNumber

      employeeFile {
        id
        filename
        createdAt
      }

      otherDocuments {
        id
        filename
        createdAt
      }

      foreignerDocuments {
        id
        filename
        createdAt
      }

      educationDocuments {
        id
        filename
        createdAt
      }

      healthInsurancePayerDocuments {
        id
        filename
        createdAt
      }
    }
  }
`;

export const LOGOUT_USER = gql`
  mutation logout {
    logout
  }
`;

export const UPDATE_LEAVING_QUESTIONNAIRE = gql`
  mutation leavingQuestionnaireUpdateClient(
    $input: LeavingQuestionnaireUpdateInputClient!
  ) {
    leavingQuestionnaireUpdateClient(input: $input) {
      state
      completedAt
      updatedAt
      questionnaireConfig
      customText
      questionnaireResult
      answer1
      answer2
      answer3
      answer4
      answer5
      answer6
      answer7
      answer8
      answer9
      answer10
      answer11
      answer12
      answer13
      answer14
      answer15
      answer16
      answer17
      answer18
      answer19
      answer20
    }
  }
`;

export const ENUM_INSURANCE = gql`
  query enumerations {
    enumerations(type: POJISTOVNA) {
      id
      text
    }
  }
`;

export const ENUM_MARITAL_STATUS = gql`
  query enumerations {
    enumerations(type: RODINNY_STAV) {
      id
      text
    }
  }
`;

export const ENUM_PREV_WORK = gql`
  query enumerations {
    enumerations(type: PREV_WORK) {
      id
      text
    }
  }
`;

export const ENUM_COUNTRY = gql`
  query enumerations {
    enumerations(type: COUNTRY) {
      id
      text
      value
    }
  }
`;

export const ENUM_EDUCATION = gql`
  query enumerations {
    enumerations(type: VZDELANI) {
      id
      text
      value
    }
  }
`;
