import { Box, Drawer, List, ListItem, ListItemText } from "@mui/material";
import { Link } from "react-router-dom";
import useConsentStore from "../../store/consentStore";

interface MobileNavType {
  hasLeavingQuestionnaire: boolean;
  isOpen: boolean;
  close: () => void;
}

const MobileNav = ({
  hasLeavingQuestionnaire,
  isOpen,
  close,
}: MobileNavType) => {
  const {
    consentOnlineText,
    consentOnlineDeletedAt,
  } = useConsentStore();

  return (
    <Drawer anchor={"left"} open={isOpen} onClose={close}>
      <Box sx={{ width: "200px" }} onClick={close}>
        <List>
          <ListItem component={Link} to="/">
            <ListItemText primary={"Rozcestník"} />
          </ListItem>

          <ListItem component={Link} to="/dotazniky">
            <ListItemText primary={"Dotazníky"} />
          </ListItem>

          {hasLeavingQuestionnaire && (
            <ListItem component={Link} to="/dotaznikvystup/1/uvod">
              <ListItemText primary={"Dotazník výstupní"} />
            </ListItem>
          )}
          <ListItem component={Link} to="/dokumenty">
            <ListItemText primary={"Šanon"} />
          </ListItem>
          {(consentOnlineText || consentOnlineDeletedAt) && (
            <ListItem component={Link} to="/consent">
              <ListItemText primary={"Elektronická komunikace"} />
            </ListItem>
          )}
        </List>
      </Box>
    </Drawer>
  );
};

export default MobileNav;
