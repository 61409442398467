import { Chip } from "@mui/material";
import { QuestionnaireStateType } from "../utils/Types/QuestionnaireStateType";
import {
  QUESTIONNAIRE_STATE_CS,
  STATE_COLORS,
} from "../utils/QuestionnaireUtils";

interface StateLabelType {
  state: QuestionnaireStateType;
}

const StateLabel = ({ state }: StateLabelType) => {
  return (
    <Chip
      label={QUESTIONNAIRE_STATE_CS[state]}
      sx={{ backgroundColor: STATE_COLORS[state] }}
      size="small"
    />
  );
};

export default StateLabel;
