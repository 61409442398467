import { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Container,
  Card,
  CardContent,
  CardMedia,
  Alert,
  Button,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import { TextField } from "formik-material-ui";
import { Formik, Field, Form } from "formik";
import passwordHero from "../../image/password-hero.png";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useMutation } from "@apollo/client";

import { RECOVER_PASSWORD } from "./gql";
import { PasswordRecoveryFormType } from "../../utils/Types/PasswordRecoveryFormType";
import CopyrightFooter from "../../components/CopyrightFooter";
import { PasswordRecoveryErrorsType } from "../../utils/Types/PasswordRecoveryErrorsType";

function PasswordRecovery() {
  const theme = useTheme();

  const [submit, { data }] = useMutation(RECOVER_PASSWORD);

  const [isShowError, setShowError] = useState(false);
  const [isRecoverSent, setRecoverSent] = useState(false);

  useEffect(() => {
    if (data) {
      //check for success of sending recoverPassword request
      if (data.recoverPassword) {
        setRecoverSent(true);
      }
    }
  }, [data]);

  const handleFormSubmit = (
    vals: PasswordRecoveryFormType,
    { setSubmitting }: any
  ) => {
    submit({
      variables: {
        email: vals.email,
      },
    })
      .catch((err) => {
        setShowError(true);
        setSubmitting(false);
      })
      .then((succ) => {
        setSubmitting(false);
      });
  };

  const validation = (values: PasswordRecoveryFormType) => {
    const errors: PasswordRecoveryErrorsType = {};
    const requiredMsg = "Pole musí být vyplněno";

    if (!values.email) {
      errors.email = requiredMsg;
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Neplatný email";
    }

    return errors;
  };

  const initialFormValues: PasswordRecoveryFormType = {
    email: "",
  };

  return (
    <>
      <Container maxWidth="md">
        <Card
          sx={{
            marginTop: theme.spacing(3),
            [theme.breakpoints.up("sm")]: {
              display: "flex",
            },
          }}
        >
          <CardMedia
            sx={{
              minHeight: 105,
              minWidth: 105,

              [theme.breakpoints.up("sm")]: {
                width: 450,
                minHeight: 450,
              },
            }}
            component="img"
            image={passwordHero}
          />
          <CardContent
            sx={{
              padding: theme.spacing(3),

              [theme.breakpoints.up("sm")]: {
                padding: theme.spacing(6),
              },
            }}
          >
            {!isRecoverSent && (
              <div>
                <Box mb={1}>
                  <Typography variant="h4">Obnova hesla</Typography>
                </Box>
                <Typography>
                  Zadej níže svůj login, který jsi použil při registraci.{" "}
                </Typography>
                {isShowError && (
                  <Box mb={2}>
                    <Alert severity="error">Neplatný email</Alert>
                  </Box>
                )}

                <Box mt={2}>
                  <Formik
                    initialValues={initialFormValues}
                    validate={validation}
                    onSubmit={handleFormSubmit}
                  >
                    <Form>
                      <label htmlFor="email">Email:</label>
                      <Field
                        component={TextField}
                        helperText=" "
                        variant="outlined"
                        size="small"
                        margin="dense"
                        fullWidth
                        name="email"
                      />

                      <Box mt={1}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          type="submit"
                        >
                          Odeslat
                        </Button>
                      </Box>
                      <Box mt={2}>
                        <Link to="/login/external">Přihlásit se</Link>
                      </Box>
                    </Form>
                  </Formik>
                </Box>
              </div>
            )}

            {/* recover is succesfully sent  */}
            {isRecoverSent && (
              <div>
                <Typography align="center">
                  <CheckCircleOutlineIcon color="primary" />
                </Typography>
                <Box mb={1}>
                  <Typography variant="h4">Požadavek odeslán</Typography>
                </Box>
                <Typography>
                  Do 10min Vám přijde na zadaný email odkaz s obnovou hesla.
                </Typography>

                <Box mt={2}>
                  <Typography>
                    Pokud žádný email neuvidíte, zkontrolujte SPAM složku.
                  </Typography>
                </Box>

                <Box mt={2}>
                  <Link to="/login/external">Přihlásit se</Link>
                </Box>
              </div>
            )}
          </CardContent>
        </Card>

        <CopyrightFooter />
      </Container>
    </>
  );
}

export default PasswordRecovery;
