import React from "react";
import { useFormikContext} from 'formik';
import FieldFactory from "./FieldFactory";

/*
Display a select field. Additionally based on the selected id option it displays a component 
*/
const SelectShowmore = (props) =>{

    const {field, isDisabled, menuItems, optionsForSelect} = props;

    const {values} = useFormikContext();

    let fieldLabel = field.label;

    if(field.isRequired){
        fieldLabel = fieldLabel + " *"
    }

    return (
        <>
            <FieldFactory isDisabled={isDisabled}
                fields={[{
                    name: field.name,
                    type: "select", 
                    label: fieldLabel ,
                    menuItems: menuItems
                }]}
            />
            {optionsForSelect.map(item=>{
                if(item.selectId === values[field.name]) return item.component;

                return "";
            })}
        </>
    )
}

export default SelectShowmore;