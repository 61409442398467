import { useEffect } from "react";
import { useFormikContext } from "formik";
import { Button, Box, Typography, useTheme } from "@mui/material";

import FieldFactory from "./FieldComponents/FieldFactory";
import RecapPageItem from "./RecapPageItem";
import { DateTime } from "luxon";

const TEXT_TYPES: any = {
  false: "Ne",
  true: "Ano",
  undefined: "",
  null: "",
};

interface RecapPageType {
  isDisabled: boolean;
  setPage: any;
  pages: any;
  fieldFilter: any;
  files: any;
  selectEnums: any;
  defaultPages: any;
}

const RecapPage = ({
  isDisabled,
  setPage,
  pages,
  fieldFilter,
  files,
  selectEnums,
  defaultPages,
}: RecapPageType) => {
  const theme = useTheme();

  const { values, isValid, errors, validateForm, setTouched } =
    useFormikContext<any>();

  useEffect(() => {
    validateForm().then(() => {
      //submit the form when the fields are invalid so they get marked red
      // if the form is valid then the user should submit the form by themselves
      if (!isValid) {
        //submitForm();
        let newTouched: { [key: string]: boolean } = {};
        Object.keys(values).forEach((key, value) => {
          newTouched[key] = true;
        });
        setTouched(newTouched);
      }
    });
  }, [values]);

  const isError = (key: string) => {
    if (key in errors) return true;
    return false;
  };

  const getReadableValue = (input: any) => {
    if (
      input === true ||
      input === false ||
      input === undefined ||
      input == null
    )
      return TEXT_TYPES[input];

    let searchValue = "";

    Object.entries(selectEnums).forEach(([key, value]: [string, any]) => {
      value.forEach((item: any) => {
        if (input === item.id) {
          searchValue = item.text;
          return searchValue;
        }

        if (searchValue.length > 0) return searchValue;
      });
    });

    return input;
  };

  //moves the page to the page for filling missing fields
  const setPageAction = (pageNumber: string) => {
    setPage(parseInt(pageNumber));
    window.scrollTo(0, 0);
  };

  //return row of a particular field and shows if it is filled or not
  const getIsErrorField = (item: any, index: number, pageNumber: any) => {
    let field: any = <></>;

    //if field is not valid the button to fill is shown
    if (isError(item.name)) {
      field = (
        <RecapPageItem
          key={index + 12592}
          item={item}
          action={() => setPageAction(pageNumber)}
          isError={true}
        />
      );
    } else {
      //has no error
      // if(item.name === "employmentOriginComment" && values["employmentOrigin"] != 56){
      //     return
      // }

      if (item.name === "employeeFile" && values["employmentOrigin"] == "846") {
        return;
      }

      //need to render files
      if (item.type === "upload") {
        //first case - files were uploaded
        return (field = (
          <RecapPageItem
            key={index * 881}
            item={item}
            filesUploaded={files[item.name]}
            isError={false}
          />
        ));
      }

      if (item.type === "date") {
        field = (
          <RecapPageItem
            key={index * 513}
            item={item}
            value={DateTime.fromJSDate(values[item.name]).toFormat("D.M.yyyy")}
            isError={false}
          />
        );
        return field;
      }

      field = (
        <RecapPageItem
          key={index * 645}
          item={item}
          value={getReadableValue(values[item.name]) + ""}
          isError={false}
        />
      );
    }

    return field;
  };

  return (
    <>
      <Box mb={1} mt={2}>
        <Typography variant="h4">Shrnutí</Typography>
      </Box>

      {Object.keys(pages).map((pageNumber, index) => {
        let array = [];
        let itemsOnPage = pages[pageNumber];

        //adding subheadlines of each section
        array.push(
          <Typography
            sx={{
              marginTop: theme.spacing(2),
            }}
            variant="h6"
          >
            <Box
              display="inline"
              sx={{
                position: "relative",
                marginRight: "10px",
              }}
            >
              {defaultPages[index].icon}
            </Box>
            {defaultPages[index].pageLabel}
          </Typography>
        );

        itemsOnPage.forEach((item: any, index2: number) => {
          //checking on
          if (item.type === "typography" || item.type === "dynamictypography")
            return;

          //dynamic Visibility
          if (item.isVisible === false) return;

          //check if filter is not empty
          if (fieldFilter !== undefined) {
            //filter out fields that shouldnt be shown
            if (item.name in fieldFilter && fieldFilter[item.name] === false) {
              return;
            }
          }

          if (item.type === "selectshowmore") {
            //56 means text field for employmentOriginComment

            array.push(getIsErrorField(item, index * 101, pageNumber));

            //checks for isSeizure and isTradeLicense
            if (item.name === "isSeizure" || item.name === "isTradeLicense") {
              if (!values[item.name]) return;
            }

            item.componentToShow.forEach((subItem: any) => {
              // if(subItem.type === "typography" || subItem.type === "dynamictypography" ) return;

              if (
                item.name === "employmentOrigin" &&
                values[item.name] == "846" &&
                subItem.name === "employeeFile"
              )
                return;

              if (subItem.selectId === values[item.name]) {
                array.push(
                  getIsErrorField(subItem, index + index2 + 3121, pageNumber)
                );
              }
            });

            return;
          }

          //fields that not valid
          array.push(getIsErrorField(item, index2 * 2232, pageNumber));

          //if has more to show if checkbox is true
          if (item.type === "checkboxshowmore" && values[item.name] === true) {
            let showmoreFields = item.componentToShow;
            showmoreFields.forEach((tmp: any, index3: number) => {
              if (tmp.type === "typography") return;
              array.push(getIsErrorField(tmp, index3 * 122511, pageNumber));
            });
          }

          if (item.type === "upload" && item.deliverLaterCheckbox) {
            item.deliverLaterCheckbox.forEach((tmp: any, index3: number) => {
              array.push(getIsErrorField(tmp, index3 * 1511, pageNumber));
            });
          }

          //the special case of birthComboRelated field, its a part of fixed BirthCombo component and its not shown
          if (item.type === "birthCombo") {
            //add the related BirthDate only if the nationality is not Ceska republika
            if (values["nationality"] !== "204") {
              item.componentRelated.forEach((tmp: any, index3: number) => {
                array.push(
                  getIsErrorField(tmp, index3 * 100 * Math.random(), pageNumber)
                );
              });
            }
          }
        });
        return array;
      })}

      {!isDisabled && (
        <div>
          <Box
            mt={3}
            sx={{
              "& .MuiFormControlLabel-label": {
                fontWeight: 700,
              },
            }}
          >
            <FieldFactory
              fields={[
                {
                  name: "agreement",
                  type: "checkbox",
                  label:
                    "Prohlašuji tímto, že všechny mnou uvedené informace v tomto dotazníku jsou úplné a pravdivé.",
                },
              ]}
              isDisabled={false}
            />

            <Typography
              sx={{
                marginTop: 1,
                color: "#909090",
              }}
              variant="body2"
            >
              Zadané údaje zpracováváme za účelem přípravy podkladů pro pracovní
              smlouvu. Při zpracování postupujeme v souladu s nařízením
              Evropského parlamentu a Rady (EU) č. 2016/679 o ochraně fyzických
              osob v souvislosti se zpracováním osobních údajů a o volném pohybu
              těchto údajů (obecné nařízení o ochraně osobních údajů), zákonem
              č. 110/2019 Sb., o zpracování osobních údajů, zákonem č. 111/2019
              Sb., kterým se mění některé zákony s přijetím zákona o zpracování
              osobních údajů.
            </Typography>
          </Box>
          <Box mt={2} mb={2}>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              type="submit"
              size="large"
              disabled={!isValid}
            >
              Odeslat
            </Button>
          </Box>
        </div>
      )}
    </>
  );
};

export default RecapPage;
