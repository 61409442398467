import { useQuery } from "@apollo/client";
import {
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid2 as Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { GET_FILE } from "./gql";
import LoadingCircle from "../../../components/LoadingCircle";
import { useEffect, useState } from "react";
import { FileTableType } from "../../../utils/Types/FileTableType";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DateTime } from "luxon";
import {
  downloadFile,
  translateEntityKind,
} from "../../../utils/FileTableUtils";
import ErrorLayout from "../../../components/layout/ErrorLayout";

const FileDetail = () => {
  const { id } = useParams();

  const [file, setFile] = useState<FileTableType | null>(null);

  const {
    loading: fileLoading,
    error: fileError,
    data: fileData,
  } = useQuery(GET_FILE, {
    variables: { id: parseInt(id || "") },
  });

  useEffect(() => {
    if (fileData) {
      setFile(fileData.fileTableSingle);
    }
  }, [fileData]);

  const handleDownload = () => {
    downloadFile(parseInt(id || ""));
  };

  if (fileLoading || !file) return <LoadingCircle />;
  if (fileError) return <ErrorLayout errorMsg={fileError.message || ""} />;

  return (
    <Container maxWidth="md">
      <Grid container>
        <Grid size={{ xs: 12 }}>
          <IconButton onClick={() => window.history.back()}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4" mb={1}>
            Detail: {file.filename}
          </Typography>
          <Card variant="outlined">
            <CardContent>
              <Grid
                container
                spacing={2}
                // justifyItems="center"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid size={{ xs: 12, md: "auto" }}>
                  <Typography variant="subtitle1">ID</Typography>
                  <Typography>{file.id}</Typography>
                </Grid>
                <Grid size={{ xs: 12, md: "auto" }}>
                  <Typography variant="subtitle1">Vytvořeno</Typography>
                  <Typography>
                    {DateTime.fromISO(file.createdAt).toFormat(
                      "d.M.yyyy HH:mm"
                    )}
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: "auto" }}>
                  <Typography variant="subtitle1">Patří uživateli</Typography>
                  <Typography>{file.owner.username}</Typography>
                </Grid>
                <Grid size={{ xs: 12, md: "auto" }}>
                  <Typography variant="subtitle1">Druh dokumentu</Typography>
                  <Typography>
                    {translateEntityKind(
                      fileData.enumerations,
                      file.entityKind
                    )}
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: "auto" }}>
                  <Button
                    variant="contained"
                    onClick={handleDownload}
                    disableElevation
                  >
                    Stáhnout
                  </Button>
                </Grid>
                {file.anotherInfo && (
                  <Grid size={{ xs: 12 }}>
                    <Divider />
                    <Typography variant="subtitle1" mt={1}>
                      Další informace
                    </Typography>
                    {file.anotherInfo}
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FileDetail;
