import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { green } from "@mui/material/colors";
import { grey } from "@mui/material/colors";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useFormikContext } from "formik";
import CancelIcon from "@mui/icons-material/Cancel";
import { motion } from "framer-motion";
import { RegisterFormValuesType } from "../../utils/Types/RegisterFormValuesType";
import { PasswordCheckerRuleType } from "../../utils/Types/PasswordCheckerRuleType";

const getLengthValidation = (pass: string) => {
  if (pass) {
    if (pass.length >= 8) return true;
  }
  return false;
};

const getSmallCaseValidation = (pass: string) => {
  if (pass) {
    if (/[a-z]/.test(pass)) return true;
  }
  return false;
};

const getCapitalValidation = (pass: string) => {
  if (pass) {
    if (/[A-Z]/.test(pass)) return true;
  }
  return false;
};

const getNumberValidation = (pass: string) => {
  if (pass) {
    if (/[0-9]/.test(pass)) return true;
  }
  return false;
};

const PasswordChecker = () => {
  const { values } = useFormikContext<RegisterFormValuesType>();

  const [rules, setRules] = useState<PasswordCheckerRuleType[]>([]);

  useEffect(() => {
    initRules();
  }, [values.password]);

  const initRules = () => {
    let rulesList = [];

    let password = values.password;

    rulesList.push({
      label: "Alespoň jedno malé písmeno",
      isCorrect: getSmallCaseValidation(password),
    });

    rulesList.push({
      label: "Alespoň jedno velké písmeno",
      isCorrect: getCapitalValidation(password),
    });

    rulesList.push({
      label: "Minimálně 8 znaků",
      isCorrect: getLengthValidation(password),
    });

    rulesList.push({
      label: "Alespoň jedna číslice",
      isCorrect: getNumberValidation(password),
    });

    setRules(rulesList);
  };

  return (
    <>
      <Box mb={2}>
        {rules.map((item, key) => {
          return (
            <Box
              key={key}
              mb={0.2}
              sx={{ color: item.isCorrect ? green[500] : grey[500] }}
            >
              {item.isCorrect ? (
                <motion.span
                  animate={{ scale: [1, 1.2, 1] }}
                  transition={{ duration: 0.3 }}
                >
                  <CheckCircleIcon
                    sx={{
                      marginBottom: "-4px",
                      marginRight: "2px",
                    }}
                    fontSize="small"
                  />
                </motion.span>
              ) : (
                <CancelIcon
                  sx={{
                    marginBottom: "-4px",
                    marginRight: "2px",
                  }}
                  fontSize="small"
                />
              )}
              {item.label}
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default PasswordChecker;

export {
  getCapitalValidation,
  getLengthValidation,
  getNumberValidation,
  getSmallCaseValidation,
};
