import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { ConsentType } from "../store/consentStore";

export const UPDATE_CONSENT_OWN_HANDS = gql`
  mutation UpdateConsentOwnHands($textId: Int!, $type: String!) {
    updateConsentOwnHands(textId: $textId, type: $type) {
      id
      type
    }
  }
`;

const useUpdateConsentOwnHands = () => {
  const navigate = useNavigate();

  const [updateConsentOwnHands] = useMutation(UPDATE_CONSENT_OWN_HANDS);

  const updateConsent = async (textId: number, type: ConsentType) => {
    const errorText = 'Nepodařilo se uložit';

    let snackbarText = '';
    switch (type) {
      case ConsentType.AGREE:
        snackbarText = 'Souhlas byl uložen';
        break;
      case ConsentType.LATER:
        snackbarText = 'Souhlas byl odložen';
        textId = 0;
        break;
      case ConsentType.WITHDRAWAL:
        snackbarText = 'Souhlas byl zrušen';
        break;
    };

    try {
      await updateConsentOwnHands({
        variables: {
          textId,
          type,
        },
        onCompleted: async () => {
          enqueueSnackbar(snackbarText, { variant: 'success' });
          setTimeout(() => {
            navigate("/");
          }, 500);
        },
        onError: () => {
          enqueueSnackbar(errorText, { variant: 'error' });
        },
        refetchQueries: "all",
      });
    } catch (error) {
      enqueueSnackbar(errorText, { variant: 'error' });
    }
  };

  return { updateConsent };
};

export default useUpdateConsentOwnHands;
