import { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Formik, Form } from "formik";
import {
  GET_LEAVING_QUESTIONNAIRE,
  UPDATE_LEAVING_QUESTIONNAIRE,
} from "../gql";
import { Button, Box, Container, Card, CardContent } from "@mui/material";
import Grid from "@mui/material/Grid2";
import LoadingCircle from "../../../../components/LoadingCircle";
import QuestionnaireGenerator from "./QuestionnaireGenerator";
import defaultInitialValues from "./FormInitialValues";
import UpdateSnackBar from "../../../../components/UpdateSnackBar";
import { isQuestionnaireDisabled } from "../../../../utils/QuestionnaireUtils";
import QuestionnaireStateMessage from "../../../../components/QuestionnaireStateMessage";
import ErrorLayout from "../../../../components/layout/ErrorLayout";
import { useNavigate } from "react-router-dom";
import { SnackBarType } from "../../../../utils/Types/SnackBarType";
import { SubmitFlagType } from "../../../../utils/Types/SubmitFlagType";

const LeavingForm = () => {
  const navigate = useNavigate();

  const [qst, setQst] = useState<any>();

  const [snackBar, setSnackBar] = useState<SnackBarType>({
    message: "",
    severity: "info",
    open: false,
  });
  const [validationFields, setValidationFields] = useState<any[]>([]);

  const { loading, error, data } = useQuery(GET_LEAVING_QUESTIONNAIRE);

  const [updateLeavingQuestionnaire, { error: updateDataError }] = useMutation(
    UPDATE_LEAVING_QUESTIONNAIRE,
    {
      update: (cache, updatedQuestionnaire) => {
        cache.writeQuery({
          query: GET_LEAVING_QUESTIONNAIRE,
          data: {
            leavingQuestionnaire:
              updatedQuestionnaire.data.leavingQuestionnaireUpdateClient,
          },
        });
      },
      onCompleted: (updatedData) => {
        if (process.env.REACT_APP_ENV_TYPE !== "PRODUCTION") {
          console.log("UPDATED");
          console.log(updatedData);
        }

        const newData = updatedData.leavingQuestionnaireUpdateClient;
        setQst(newData);

        if (newData.state === "sent") {
          navigate("/dotaznikvystup");
        }

        setSnackBar({ open: true, message: "Uloženo", severity: "success" });
      },
    }
  );

  // useEffect(() => {
  //   if (data) {
  //     if (process.env.REACT_APP_ENV_TYPE !== "PRODUCTION") {
  //       console.log(data.leavingQuestionnaire);
  //       setQst(data.leavingQuestionnaire);

  //       setValidationFields(
  //         parseFieldsToValidate(
  //           JSON.parse(data.leavingQuestionnaire.questionnaireConfig)
  //         )
  //       );
  //     }
  //   }

  //   setSnackBar({ open: true, message: "Uloženo", severity: "success" });
  // }, [data]);

  useEffect(() => {
    if (data) {
      if (process.env.REACT_APP_ENV_TYPE !== "PRODUCTION") {
        console.log(data.leavingQuestionnaire);
      }

      setQst(data.leavingQuestionnaire);
      setValidationFields(
        parseFieldsToValidate(
          JSON.parse(data.leavingQuestionnaire.questionnaireConfig)
        )
      );
    }
  }, [data]);

  const parseFieldsToValidate = (qstConfig: any) => {
    const fieldList = [];

    for (let key in qstConfig) {
      const item = qstConfig[key];

      if (item.name && item.isRequired) {
        fieldList.push(item.name);
      }
    }

    console.log(fieldList);
    return fieldList;
  };

  const getInitialValues = () => {
    let initials: any = {};

    let keys = Object.keys(defaultInitialValues);

    keys.map((key: any) => {
      const item = qst[key];

      if (item === null) {
        initials[key] = null;
      } else {
        initials[key] = item;
      }

      return "";
    });

    return initials;
  };

  const validation = (vals: any) => {
    const errors: any = {};
    const requiredLabel = "Musí být vyplněno";

    validationFields.forEach((item: any) => {
      if (!vals[item]) {
        errors[item] = requiredLabel;
      }
    });

    console.log(errors);

    return errors;
  };

  const handleSnackBarClose = () => {
    setSnackBar({ open: false, severity: "info", message: "" });
  };

  const handleSubmit = (
    values: any,
    flag: SubmitFlagType,
    setSubmitting: any
  ) => {
    setSubmitting(true);

    let newValues = Object.assign({}, values);

    if (flag === "sent") {
      newValues.state = "sent";
    }

    updateLeavingQuestionnaire({ variables: { input: newValues } }).then(() => {
      setSubmitting(false);
    });
  };

  if (updateDataError)
    return <ErrorLayout errorMsg={updateDataError.message} />;
  if (error) return <ErrorLayout errorMsg={error.message || ""} />;
  if (loading) return <LoadingCircle />;
  if (!qst) return <LoadingCircle />;

  // if (Object.keys(qst).length === 0) return <LoadingCircle />;

  return (
    <>
      <Formik
        initialValues={getInitialValues()}
        validate={validation}
        enableReinitialize
        onSubmit={() => {}}
      >
        {({ isSubmitting, values, setSubmitting, isValid, dirty }) => (
          <Form>
            <Container maxWidth="lg">
              <Grid spacing={3} container justifyContent={"center"}>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Card>
                    <CardContent>
                      <QuestionnaireStateMessage state={qst.state} />
                      <Grid size={{ xs: 12 }}>
                        <QuestionnaireGenerator
                          config={
                            values.questionnaireConfig.length > 0
                              ? values.questionnaireConfig
                              : qst.questionnaireConfig
                          }
                          isDisabled={isQuestionnaireDisabled(qst.state)}
                        />
                      </Grid>

                      {!isQuestionnaireDisabled(qst.state) && (
                        <div>
                          {!isSubmitting && (
                            <>
                              <Box mb={2}>
                                <Button
                                  disableElevation
                                  fullWidth
                                  variant={"contained"}
                                  onClick={() =>
                                    handleSubmit(
                                      values,
                                      "update",
                                      setSubmitting
                                    )
                                  }
                                  disabled={!dirty || isSubmitting}
                                >
                                  Uložit
                                </Button>
                              </Box>
                              <Button
                                fullWidth
                                disableElevation
                                variant={"contained"}
                                color="primary"
                                onClick={() =>
                                  handleSubmit(values, "sent", setSubmitting)
                                }
                                disabled={!isValid || isSubmitting}
                              >
                                Odeslat
                              </Button>
                            </>
                          )}
                          {isSubmitting && <>{LoadingCircle}</>}
                        </div>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Container>
          </Form>
        )}
      </Formik>
      <UpdateSnackBar
        message={snackBar.message}
        onClose={handleSnackBarClose}
        isOpen={snackBar.open}
        severity={snackBar.severity}
      />
    </>
  );
};

export default LeavingForm;
