import { Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Link from "@mui/material/Link";
import parse from "html-react-parser";
import { Dispatch, SetStateAction } from "react";

interface FaqType {
  setPageIndex: Dispatch<SetStateAction<number>>;
  pages: any;
}

const Faq = ({ setPageIndex, pages }: FaqType) => {
  const faqs = [
    {
      headline: "Nemůžu odeslat formulář",
      text: "Zkontroluj, zda máš zaškrtnuto na stránce ",
      icon: (
        <Link
          href="#"
          onClick={() => {
            setPageIndex(pages.length - 1);
          }}
        >
          {" "}
          <b>Shrnutí</b>
          <CheckCircleIcon
            sx={{
              marginBottom: "-2px",
              fontSize: 16,
            }}
          />
        </Link>
      ),
      text2:
        ' úplně dole <i>"Prohlašuji tímto, že všechny mnou uvedené informace v tomto dotazníku jsou úplné a pravdivé."</i>',
    },
    {
      headline: "Nemám zápočtový list",
      text: "Nevadí, na stránce",
      icon: (
        <Link
          href="#"
          onClick={() => {
            setPageIndex(4);
          }}
        >
          {" "}
          <b>Práce</b>
          <BusinessCenterIcon
            sx={{
              marginBottom: "-2px",
              fontSize: 16,
            }}
          />
        </Link>
      ),
      text2:
        ' zaškrtni položku <i>"Požadovaný dokument ještě nemám, dodám později."</i>',
    },
    {
      headline: "Nemám kopii z úřadu práce",
      text: "Nevadí, na stránce",
      icon: (
        <Link
          href="#"
          onClick={() => {
            setPageIndex(4);
          }}
        >
          {" "}
          <b>Práce</b>
          <BusinessCenterIcon
            sx={{
              marginBottom: "-2px",
              fontSize: 16,
            }}
          />
        </Link>
      ),
      text2:
        ' zaškrtni položku <i>"Požadovaný dokument ještě nemám, dodám později."</i>',
    },
  ];

  return (
    <>
      <Typography
        variant="h6"
        style={{ display: "flex", alignItems: "center" }}
      >
        Často kladené otázky
      </Typography>
      {faqs.map((item) => {
        return (
          <Accordion disableGutters elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{item.headline}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {parse(item.text)}
                {item.icon}
                {parse(item.text2)}
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};

export default Faq;
