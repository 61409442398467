import styles from './styles.module.css';

const HighlightBlock = ({
  children,
  style,
  className = "",
  ...props
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  props?: any;
}) => {
  return (
    <div
      style={style}
      className={`${styles.main} ${className}`}
      {...props}
    >
      <div className={styles.circles} />
      {children}
      <div className={`${styles.circles} ${styles['circles--bottom']}`} />
    </div>
  );
};

export default HighlightBlock;
