import { create } from 'zustand'

interface User {
  email: string
}

interface UserStore {
  user: User
  setUser: (user: User) => void

  hasLeavingQuestionnaire: boolean
  setHasLeavingQuestionnaire: (hasLeavingQuestionnaire: boolean) => void

  hasConsentOnline: boolean
  setHasConsentOnline: (hasConsentOnline: boolean) => void

  ico: number
  setIco: (ico: number) => void
}

const useUserStore = create<UserStore>((set) => ({
  user: {
    email: '',
  },
  setUser: (user) => set({ user }),

  hasLeavingQuestionnaire: false,
  setHasLeavingQuestionnaire: (hasLeavingQuestionnaire) => set({ hasLeavingQuestionnaire }),

  hasConsentOnline: false,
  setHasConsentOnline: (hasConsentOnline) => set({ hasConsentOnline }),

  ico: 0,
  setIco: (ico) => set({ ico }),
}))

export default useUserStore
