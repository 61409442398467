import { gql } from "@apollo/client";

export const GET_QUESTIONNAIRES = gql`
  query {
    questionnaires {
      id
      firstName
      lastName
      birthLastName
      email
      phone
      birthNumber
      birthDate
      birthPlace
      state
      nationality
      maritalStatus
      customText
      customText2
      customText3
      isForeigner
      isBankAccountShow
      bankAccountNumber
      permanentAddressCity
      permanentAddressStreet
      permanentAddressPostnumber
      isContactAddressDifferent
      contactAddressCity
      contactAddressStreet
      contactAddressPostnumber
      children
      insuranceNumber
      insuranceCompany
      isHandicap
      isOtherEmployment
      idCardType
      idCardNumber
      idCardValidDate
      employmentOrigin
      isSeizure
      seizureNumber
      isTradeLicense
      tradingLicenseNumber
      isDeliverFileLater
      isCriminalRecord
      educationLevel
      educationCode
      isEducationDeliverFileLater
      isNoInsuranceCompany
      typeOfCandidate
      otherHealthInsurancePayer
      companyIdentificationNumber
      startDate
      jobTitle
      typeOfEmployment

      employeeFile {
        id
        filename
        createdAt
      }

      otherDocuments {
        id
        filename
        createdAt
      }

      foreignerDocuments {
        id
        filename
        createdAt
      }

      educationDocuments {
        id
        filename
        createdAt
      }

      healthInsurancePayerDocuments {
        id
        filename
        createdAt
      }

      updatedAt
    }
  }
`;
