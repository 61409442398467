import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import green from "@mui/material/colors/green";
import red from "@mui/material/colors/red";
import SnackbarContent from "@mui/material/SnackbarContent";
import { SeverityType } from "../utils/Types/SeverityType";

interface UpdateSnackBarType {
  message: string;
  isOpen: boolean;
  onClose: () => void;
  severity: SeverityType;
}
const UpdateSnackBar = ({
  message,
  isOpen,
  onClose,
  severity,
}: UpdateSnackBarType) => {
  const getSnackBarStyle = () => {
    if (severity === "error") {
      return {
        backgroundColor: red[600],
      };
    } else if (severity === "success") {
      return {
        backgroundColor: green[600],
      };
    }
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={isOpen}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <SnackbarContent
        sx={getSnackBarStyle()}
        message={message}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Snackbar>
  );
};

export default UpdateSnackBar;
