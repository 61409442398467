import { useState, useEffect } from "react";
import {
  Typography,
  Input,
  Box,
  FormHelperText,
  LinearProgress,
  Alert,
  useTheme,
} from "@mui/material";
import { useFormikContext } from "formik";
import UploadDisplay from "./UploadDisplay";
import FieldFactory from "../FieldFactory";

interface UploaderType {
  field: any;
  isDisabled: boolean;
  files: any;
  handleFileDelete: any;
  handleFileUpload: any;
  deliverLaterCheckbox: any;
}

const Uploader = ({
  field,
  isDisabled,
  files,
  handleFileDelete,
  handleFileUpload,
  deliverLaterCheckbox,
}: UploaderType) => {
  const theme = useTheme();

  const {
    values,
    touched,
    errors,
    setFieldValue,
    setSubmitting,
    isSubmitting,
    setErrors,
  } = useFormikContext<any>();

  const [fileList, setFileList] = useState<any>([]);
  const [uploadFileName, setUploadFileName] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeliverFileChecked, setIsDeliverFileChecked] = useState(false);
  const [isMaxFilesUploaded, setMaxFilesUploaded] = useState(false);

  const filesName = field.name;
  let inputLabel = field.label;

  const MAX_UPLOADED_FILES = 20;

  if (field.isRequired) inputLabel = inputLabel + " *";

  useEffect(() => {
    if (files.length >= MAX_UPLOADED_FILES) {
      setMaxFilesUploaded(true);
    } else {
      setMaxFilesUploaded(false);
    }
  });

  useEffect(() => {
    if (!isSubmitting) {
      setIsUploading(false);
      setIsDeleting(false);
    }
  }, [isSubmitting]);

  useEffect(() => {
    if (deliverLaterCheckbox) {
      const checkboxName = deliverLaterCheckbox[0].name;
      if (values[checkboxName]) {
        setIsDeliverFileChecked(true);
      } else {
        setIsDeliverFileChecked(false);
      }
    }
  }, [values]);

  const handleUploadChange = (e: any) => {
    const newFile = e.currentTarget?.files[0];
    let tmpFileList = fileList;

    if (!newFile) return;

    const fileSize = newFile.size / 1000000;

    //check the max file size 20Mb
    if (fileSize > 20) {
      errors[filesName] = "Maximální velikost souboru pro nahrání je 20 MB";
      setErrors(errors);
      //calling set method to trigger rerender so the error gets shown
      setUploadFileName(e.target.value.split(/(\\|\/)/g).pop());
      return;
    }

    setUploadFileName(e.target.value.split(/(\\|\/)/g).pop());
    setIsUploading(true);

    let fileActions: { create: any; delete: any } = { create: [], delete: [] };

    fileActions.create.push(newFile);
    tmpFileList = fileList.concat([newFile]);

    setFieldValue(filesName, fileActions);
    setFileList(tmpFileList);

    values[filesName] = fileActions;
    handleFileUpload(filesName, values, setSubmitting, setFieldValue);
    e.target.value = null;
  };

  const handleDeleteChange = (itemID: any, fileName: string) => {
    setIsDeleting(true);
    setUploadFileName(fileName);
    handleFileDelete(filesName, itemID, setFieldValue, values, setSubmitting);
  };

  return (
    <>
      <Box
        sx={{
          color: errors[filesName] ? theme.palette.error.main : "inherit",
        }}
      >
        <Typography>{inputLabel}</Typography>
        <Input
          sx={{
            border: "2px dashed #c2c2c2",
            borderColor: errors[filesName]
              ? theme.palette.error.main
              : "#c2c2c2",
            padding: "15px",
            borderRadius: theme.shape.borderRadius,
            width: "100%",

            "&::before": {
              content: `""`,
              display: "none",
            },
            "&::after": {
              content: `""`,
              display: "none",
            },
          }}
          type="file"
          disabled={isDisabled || isDeliverFileChecked || isMaxFilesUploaded}
          onChange={(e) => {
            handleUploadChange(e);
          }}
        />
        {errors[filesName] && touched[filesName] && (
          <FormHelperText sx={{ color: theme.palette.error.main }}>
            <>{errors[filesName]}</>
          </FormHelperText>
        )}
        {isSubmitting && (isUploading || isDeleting) && (
          <Box mt={1}>
            {" "}
            Soubor <i>{uploadFileName}</i> se {isUploading ? "nahrává" : "maže"}{" "}
            <LinearProgress />
          </Box>
        )}
      </Box>

      {isMaxFilesUploaded && (
        <Box mt={2}>
          <Alert severity="info">
            Maximální počet souborů, které můžete nahrát je 20.
          </Alert>
        </Box>
      )}

      <Box mt={2}>
        {files.length > 0 && (
          <UploadDisplay
            isDisabled={isDisabled}
            handleDelete={handleDeleteChange}
            filesName={filesName}
            files={files}
          />
        )}
      </Box>

      <Box mt={2}>
        {deliverLaterCheckbox && (
          <FieldFactory
            isDisabled={isDisabled || files.length > 0 || isSubmitting}
            fields={deliverLaterCheckbox}
          />
        )}
      </Box>
    </>
  );
};
export default Uploader;
