import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./App.css";
import { ApolloProvider } from "@apollo/client";
import {
  ThemeProvider,
  responsiveFontSizes,
  CssBaseline,
  createTheme,
} from "@mui/material";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { from } from "@apollo/client";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { grey } from "@mui/material/colors";
import { SnackbarProvider } from "notistack";
import { AppRouter } from "./router";

let theme = createTheme({
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
      },
    },
  },

  typography: {
    fontFamily: [
      "TriviaSeznam",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: { fontWeight: 700 },
    h2: { fontWeight: 600, fontSize: "2.75rem" },
    h3: {
      fontWeight: 600,
      fontSize: "1.5rem",
    },
    h4: { fontWeight: 700 },
    h5: { fontWeight: 700 },
    h6: {
      fontWeight: 700,
    },
    subtitle1: {
      color: grey[600],
    },
    button: {
      textTransform: "none",
      fontWeight: 700,
    },
  },
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#CC0000",
    },
  },
});

theme = responsiveFontSizes(theme);

const cred = "include";

const link = createUploadLink({
  uri: process.env.REACT_APP_SERVER_BASE_PATH + "/graphql",
  credentials: cred,
  headers: { "Apollo-Require-Preflight": "true" },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  cache: new InMemoryCache({ addTypename: false }),
  link: from([errorLink, link]),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <SnackbarProvider maxSnack={3}>
            <AppRouter />
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

// reportWebVitals();
