import { useState } from "react";
import gql from "graphql-tag";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import useUserStore from "../../store/userStore";
import { enqueueSnackbar } from "notistack";
import useConsentStore, { ConsentType } from "../../store/consentStore";
import { useMutation } from "@apollo/client";
import useUpdateConsentOwnHands from "../../hooks/useUpdateConsentOwnHands";

const DELETE_CONSENT_ONLINE_CONTACT_INFO = gql`
  mutation DeleteConsentOnlineContactInfo {
    deleteConsentOnlineContactInfo
  }
`;

const CancelConsent = () => {
  const {
    setHasConsentOnline,
  } = useUserStore();

  const {
    setConsentType,
    consentOnlineCreatedById,
    setEmail,
    setPhone,
    consentOwnHandsText,
    consentOnlineDeletedAt,
  } = useConsentStore();

  const [open, setOpen] = useState(false);

  const { updateConsent } = useUpdateConsentOwnHands();

  const [deleteConsentOnlineContactInfo, {
    error: deleteConsentOnlineContactInfoError,
  }] = useMutation(DELETE_CONSENT_ONLINE_CONTACT_INFO, {
    refetchQueries: "all",
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteConsentOnlineContactInfo = () => {
    deleteConsentOnlineContactInfo();
    if (deleteConsentOnlineContactInfoError) {
      enqueueSnackbar('Nelze zrušit souhlas', { variant: 'error' });
      return;
    }
  };

  const handleConsentWithdrawal = () => {
    if (!consentOwnHandsText?.id) {
      handleDeleteConsentOnlineContactInfo();
      enqueueSnackbar('Údaje byly smazány', { variant: 'success' });
      setOpen(false);
      setEmail('');
      setPhone('');
    } else {
      handleDeleteConsentOnlineContactInfo();
      setOpen(false);
      setHasConsentOnline(false);
      setConsentType(ConsentType.WITHDRAWAL);
      setEmail('');
      setPhone('');
      updateConsent(consentOwnHandsText.id, ConsentType.WITHDRAWAL);
    }
  };

  return (
    <>
      <Button
        disabled={
          consentOnlineCreatedById !== null
          || consentOnlineDeletedAt !== null
        }
        variant="outlined"
        disableElevation
        color="primary"
        onClick={() => setOpen(true)}
      >
        Smazat údaje a odvolat souhlas
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          Opravdu chcete zrušit a odvolat souhlas?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Opravdu chceš zrušit souhlas a připravit se o výhody elektronické komunikace.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConsentWithdrawal}>Ano</Button>
          <Button onClick={handleClose} autoFocus>
            Ne
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CancelConsent;
